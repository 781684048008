export const availablePincodes = [
  "560001",
  "560004",
  "110001",
  "110002",
  "110003",
  "110004",
  "110005",
  "110006",
  "110007",
  "110008",
  "110009",
  "110010",
  "110011",
  "110012",
  "110013",
  "110014",
  "110015",
  "110016",
  "110017",
  "110018",
  "110019",
  "110020",
  "110021",
  "110022",
  "110023",
  "110024",
  "110025",
  "110026",
  "110027",
  "110028",
  "110029",
  "110030",
  "110031",
  "110032",
  "110033",
  "110034",
  "110035",
  "110037",
  "110038",
  "110039",
  "110041",
  "110042",
  "110043",
  "110044",
  "110045",
  "110046",
  "110047",
  "110048",
  "110049",
  "110051",
  "110052",
  "110053",
  "110054",
  "110055",
  "110056",
  "110057",
  "110058",
  "110059",
  "110060",
  "110061",
  "110062",
  "110063",
  "110064",
  "110065",
  "110066",
  "110067",
  "110068",
  "110069",
  "110070",
  "110071",
  "110072",
  "110073",
  "110074",
  "110075",
  "110076",
  "110077",
  "110078",
  "110080",
  "110081",
  "110083",
  "110084",
  "110085",
  "110086",
  "110087",
  "110088",
  "110089",
  "110090",
  "110091",
  "110092",
  "110093",
  "110095",
  "110096",
  "110097",
  "121001",
  "121002",
  "121003",
  "121004",
  "121005",
  "121006",
  "121007",
  "121008",
  "121009",
  "121010",
  "121012",
  "121013",
  "122001",
  "122002",
  "122003",
  "122004",
  "122005",
  "122006",
  "122007",
  "122008",
  "122009",
  "122010",
  "122011",
  "122013",
  "122015",
  "122016",
  "122017",
  "122018",
  "122022",
  "122050",
  "122051",
  "122052",
  "122101",
  "122102",
  "122505",
  "123001",
  "124001",
  "124021",
  "124201",
  "125001",
  "125005",
  "125055",
  "127021",
  "131001",
  "132001",
  "132103",
  "132106",
  "133001",
  "133004",
  "133005",
  "133006",
  "133021",
  "133104",
  "133302",
  "134002",
  "134003",
  "134007",
  "134011",
  "134102",
  "134107",
  "134108",
  "134109",
  "134112",
  "134113",
  "134114",
  "134115",
  "134116",
  "134117",
  "134201",
  "134202",
  "134203",
  "135001",
  "135002",
  "135003",
  "140101",
  "140103",
  "140301",
  "140307",
  "140308",
  "140603",
  "140604",
  "141001",
  "141002",
  "141003",
  "141004",
  "141005",
  "141006",
  "141007",
  "141008",
  "141009",
  "141010",
  "141011",
  "141012",
  "141013",
  "141014",
  "141015",
  "141016",
  "141017",
  "141020",
  "143001",
  "143002",
  "143003",
  "143004",
  "143005",
  "143006",
  "143007",
  "143008",
  "143105",
  "143505",
  "144001",
  "144002",
  "144003",
  "144004",
  "144005",
  "144006",
  "144008",
  "144009",
  "144022",
  "147001",
  "147002",
  "147003",
  "147004",
  "147005",
  "147201",
  "160001",
  "160002",
  "160003",
  "160009",
  "160011",
  "160012",
  "160014",
  "160015",
  "160018",
  "160019",
  "160020",
  "160022",
  "160023",
  "160027",
  "160028",
  "160030",
  "160035",
  "160036",
  "160037",
  "160038",
  "160040",
  "160043",
  "160044",
  "160045",
  "160047",
  "160055",
  "160059",
  "160060",
  "160061",
  "160062",
  "160066",
  "160069",
  "160071",
  "160101",
  "160102",
  "160103",
  "160104",
  "180001",
  "180002",
  "180003",
  "180004",
  "180005",
  "180007",
  "180010",
  "180011",
  "180012",
  "180013",
  "180015",
  "181121",
  "181152",
  "201001",
  "201002",
  "201003",
  "201005",
  "201007",
  "201009",
  "201010",
  "201011",
  "201012",
  "201013",
  "201014",
  "201015",
  "201016",
  "201017",
  "201019",
  "201102",
  "201201",
  "201204",
  "201206",
  "201301",
  "201302",
  "201303",
  "201304",
  "201305",
  "201306",
  "201307",
  "201308",
  "201310",
  "201311",
  "202001",
  "202002",
  "202125",
  "202139",
  "203001",
  "203207",
  "204101",
  "204102",
  "207001",
  "207123",
  "208001",
  "208002",
  "208003",
  "208004",
  "208005",
  "208006",
  "208007",
  "208008",
  "208010",
  "208011",
  "208012",
  "208013",
  "208014",
  "208015",
  "208016",
  "208017",
  "208019",
  "208020",
  "208021",
  "208022",
  "208023",
  "208024",
  "208025",
  "208026",
  "208027",
  "209801",
  "209861",
  "210001",
  "211001",
  "211002",
  "211003",
  "211004",
  "211005",
  "211006",
  "211007",
  "211008",
  "211010",
  "211011",
  "211012",
  "211013",
  "211014",
  "211016",
  "211017",
  "211019",
  "221001",
  "221002",
  "221003",
  "221004",
  "221005",
  "221007",
  "221008",
  "221010",
  "221011",
  "222001",
  "222002",
  "222003",
  "222137",
  "222170",
  "222180",
  "222203",
  "224001",
  "225001",
  "225301",
  "225304",
  "225305",
  "225306",
  "226001",
  "226002",
  "226003",
  "226004",
  "226005",
  "226006",
  "226007",
  "226008",
  "226009",
  "226010",
  "226011",
  "226012",
  "226013",
  "226014",
  "226015",
  "226016",
  "226017",
  "226018",
  "226020",
  "226021",
  "226022",
  "226023",
  "226024",
  "226025",
  "226028",
  "226029",
  "226030",
  "226031",
  "226401",
  "228001",
  "230001",
  "231001",
  "231205",
  "231207",
  "231216",
  "231219",
  "231301",
  "233001",
  "241001",
  "242001",
  "243001",
  "243002",
  "243003",
  "243004",
  "243005",
  "243006",
  "243122",
  "243123",
  "243502",
  "244001",
  "244713",
  "244715",
  "244901",
  "247001",
  "247340",
  "247342",
  "248001",
  "248003",
  "248004",
  "248005",
  "248006",
  "248007",
  "248009",
  "248013",
  "248140",
  "248171",
  "249137",
  "249201",
  "249202",
  "249203",
  "249401",
  "249402",
  "249403",
  "249404",
  "249405",
  "249407",
  "249408",
  "249410",
  "249411",
  "250001",
  "250002",
  "250003",
  "250004",
  "250110",
  "250223",
  "251001",
  "251002",
  "263126",
  "263136",
  "263138",
  "263139",
  "263145",
  "263148",
  "263149",
  "263152",
  "263153",
  "271001",
  "271002",
  "271003",
  "271125",
  "271801",
  "272001",
  "272002",
  "272175",
  "273001",
  "273002",
  "273003",
  "273004",
  "273005",
  "273006",
  "273007",
  "273008",
  "273009",
  "273010",
  "273013",
  "273014",
  "273015",
  "273016",
  "273017",
  "274001",
  "277001",
  "277202",
  "277304",
  "277401",
  "277503",
  "277506",
  "281001",
  "281003",
  "281004",
  "281006",
  "281121",
  "282001",
  "282002",
  "282003",
  "282004",
  "282005",
  "282006",
  "282007",
  "282008",
  "282010",
  "284001",
  "284002",
  "284003",
  "284121",
  "284128",
  "284135",
  "284419",
  "301019",
  "302001",
  "302002",
  "302003",
  "302004",
  "302005",
  "302006",
  "302007",
  "302008",
  "302011",
  "302012",
  "302013",
  "302015",
  "302016",
  "302017",
  "302018",
  "302019",
  "302020",
  "302021",
  "302022",
  "302023",
  "302024",
  "302026",
  "302028",
  "302029",
  "302031",
  "302033",
  "302034",
  "302035",
  "302039",
  "305001",
  "305002",
  "305003",
  "305004",
  "305005",
  "305006",
  "305007",
  "305008",
  "305023",
  "311001",
  "313001",
  "313002",
  "313003",
  "313004",
  "313010",
  "313011",
  "313024",
  "324001",
  "324002",
  "324003",
  "324004",
  "324005",
  "324006",
  "324007",
  "324008",
  "324009",
  "324010",
  "334001",
  "334002",
  "334003",
  "334004",
  "334005",
  "335512",
  "335513",
  "342001",
  "342002",
  "342003",
  "342004",
  "342005",
  "342006",
  "342007",
  "342008",
  "342011",
  "342014",
  "342015",
  "342304",
  "360001",
  "360002",
  "360003",
  "360004",
  "360005",
  "360006",
  "360007",
  "361001",
  "361002",
  "361003",
  "361004",
  "361005",
  "361006",
  "361007",
  "361008",
  "370001",
  "370110",
  "370201",
  "370205",
  "380001",
  "380002",
  "380003",
  "380004",
  "380005",
  "380006",
  "380007",
  "380008",
  "380009",
  "380013",
  "380014",
  "380015",
  "380016",
  "380018",
  "380019",
  "380021",
  "380022",
  "380023",
  "380024",
  "380025",
  "380026",
  "380027",
  "380028",
  "380050",
  "380051",
  "380052",
  "380053",
  "380054",
  "380055",
  "380058",
  "380059",
  "380060",
  "380061",
  "380063",
  "382001",
  "382002",
  "382003",
  "382006",
  "382007",
  "382008",
  "382009",
  "382010",
  "382011",
  "382012",
  "382016",
  "382020",
  "382021",
  "382022",
  "382024",
  "382027",
  "382028",
  "382029",
  "382030",
  "382325",
  "382330",
  "382340",
  "382345",
  "382346",
  "382350",
  "382410",
  "382415",
  "382418",
  "382421",
  "382422",
  "382424",
  "382428",
  "382430",
  "382435",
  "382445",
  "382449",
  "382470",
  "382480",
  "382481",
  "382610",
  "387001",
  "388001",
  "388110",
  "388120",
  "388121",
  "388315",
  "388345",
  "388450",
  "388540",
  "389001",
  "390001",
  "390002",
  "390003",
  "390004",
  "390005",
  "390006",
  "390007",
  "390008",
  "390009",
  "390010",
  "390011",
  "390012",
  "390013",
  "390014",
  "390015",
  "390016",
  "390017",
  "390018",
  "390019",
  "390020",
  "390021",
  "390022",
  "390023",
  "390024",
  "390025",
  "391345",
  "391410",
  "394210",
  "394220",
  "394221",
  "394230",
  "394370",
  "394510",
  "395001",
  "395002",
  "395003",
  "395004",
  "395006",
  "395007",
  "395008",
  "395009",
  "395010",
  "395012",
  "395023",
  "396191",
  "396195",
  "400001",
  "400002",
  "400003",
  "400004",
  "400005",
  "400006",
  "400007",
  "400008",
  "400009",
  "400010",
  "400011",
  "400012",
  "400013",
  "400014",
  "400015",
  "400016",
  "400017",
  "400018",
  "400019",
  "400020",
  "400021",
  "400022",
  "400023",
  "400024",
  "400025",
  "400026",
  "400027",
  "400028",
  "400029",
  "400030",
  "400031",
  "400032",
  "400033",
  "400034",
  "400035",
  "400036",
  "400037",
  "400038",
  "400039",
  "400042",
  "400043",
  "400049",
  "400050",
  "400051",
  "400052",
  "400053",
  "400054",
  "400055",
  "400056",
  "400057",
  "400058",
  "400059",
  "400060",
  "400061",
  "400062",
  "400063",
  "400064",
  "400065",
  "400066",
  "400067",
  "400068",
  "400069",
  "400070",
  "400071",
  "400072",
  "400073",
  "400074",
  "400075",
  "400076",
  "400077",
  "400078",
  "400079",
  "400080",
  "400081",
  "400082",
  "400083",
  "400084",
  "400086",
  "400087",
  "400088",
  "400089",
  "400090",
  "400091",
  "400092",
  "400093",
  "400094",
  "400095",
  "400096",
  "400097",
  "400098",
  "400099",
  "400101",
  "400102",
  "400103",
  "400104",
  "400601",
  "400602",
  "400603",
  "400604",
  "400605",
  "400606",
  "400607",
  "400608",
  "400609",
  "400610",
  "400612",
  "400614",
  "400615",
  "400701",
  "400703",
  "400705",
  "400706",
  "400708",
  "400709",
  "400710",
  "401101",
  "401104",
  "401105",
  "401107",
  "401201",
  "401202",
  "401203",
  "401207",
  "401208",
  "401209",
  "401210",
  "401301",
  "401303",
  "401304",
  "401305",
  "401404",
  "401501",
  "401502",
  "401504",
  "401506",
  "403001",
  "403002",
  "403601",
  "403602",
  "410206",
  "410208",
  "410209",
  "410210",
  "410218",
  "411001",
  "411002",
  "411003",
  "411005",
  "411006",
  "411007",
  "411008",
  "411009",
  "411011",
  "411013",
  "411014",
  "411015",
  "411016",
  "411017",
  "411018",
  "411019",
  "411020",
  "411021",
  "411022",
  "411023",
  "411024",
  "411026",
  "411027",
  "411028",
  "411030",
  "411031",
  "411032",
  "411033",
  "411036",
  "411037",
  "411038",
  "411039",
  "411040",
  "411041",
  "411042",
  "411043",
  "411044",
  "411045",
  "411046",
  "411047",
  "411048",
  "411051",
  "411052",
  "411057",
  "411058",
  "411060",
  "411061",
  "411062",
  "412101",
  "412103",
  "412105",
  "412114",
  "412207",
  "412307",
  "412308",
  "413001",
  "413002",
  "413003",
  "413004",
  "413005",
  "413006",
  "413007",
  "413008",
  "413102",
  "413110",
  "413115",
  "413116",
  "413512",
  "413531",
  "414001",
  "414002",
  "414003",
  "414005",
  "414111",
  "415001",
  "415002",
  "415003",
  "415004",
  "415005",
  "415105",
  "415109",
  "415110",
  "415114",
  "415124",
  "415205",
  "415302",
  "415401",
  "415403",
  "415406",
  "415407",
  "415409",
  "415413",
  "415539",
  "415612",
  "415639",
  "416001",
  "416002",
  "416003",
  "416004",
  "416005",
  "416006",
  "416007",
  "416008",
  "416012",
  "416313",
  "416410",
  "416415",
  "416416",
  "421001",
  "421002",
  "421003",
  "421004",
  "421005",
  "421103",
  "421201",
  "421202",
  "421203",
  "421204",
  "421301",
  "421302",
  "421305",
  "421306",
  "421308",
  "421311",
  "421501",
  "421502",
  "421503",
  "421504",
  "421505",
  "421506",
  "421601",
  "421605",
  "422001",
  "422002",
  "422003",
  "422004",
  "422005",
  "422006",
  "422007",
  "422008",
  "422009",
  "422010",
  "422011",
  "422012",
  "422013",
  "422101",
  "422102",
  "422209",
  "422214",
  "422401",
  "424001",
  "424002",
  "424003",
  "424004",
  "424005",
  "425001",
  "425002",
  "425003",
  "425004",
  "431001",
  "431002",
  "431003",
  "431004",
  "431005",
  "431006",
  "431007",
  "431008",
  "431009",
  "431010",
  "431104",
  "431105",
  "431110",
  "431122",
  "431136",
  "431203",
  "431401",
  "431601",
  "431602",
  "431603",
  "431604",
  "431605",
  "440001",
  "440002",
  "440003",
  "440004",
  "440005",
  "440006",
  "440007",
  "440008",
  "440009",
  "440010",
  "440011",
  "440012",
  "440013",
  "440014",
  "440015",
  "440016",
  "440017",
  "440018",
  "440019",
  "440020",
  "440021",
  "440022",
  "440023",
  "440024",
  "440025",
  "440026",
  "440027",
  "440030",
  "440032",
  "440033",
  "440034",
  "440035",
  "440036",
  "440037",
  "441111",
  "441123",
  "442401",
  "442402",
  "442403",
  "442404",
  "444001",
  "444002",
  "444003",
  "444004",
  "444005",
  "444601",
  "444602",
  "444603",
  "444604",
  "444605",
  "444606",
  "444607",
  "452001",
  "452002",
  "452003",
  "452004",
  "452005",
  "452006",
  "452007",
  "452008",
  "452009",
  "452010",
  "452011",
  "452012",
  "452013",
  "452014",
  "452015",
  "452016",
  "452018",
  "452020",
  "455001",
  "456001",
  "456003",
  "456006",
  "456010",
  "457001",
  "461001",
  "461111",
  "462001",
  "462003",
  "462004",
  "462008",
  "462010",
  "462011",
  "462012",
  "462013",
  "462016",
  "462020",
  "462021",
  "462022",
  "462023",
  "462024",
  "462026",
  "462027",
  "462030",
  "462031",
  "462032",
  "462036",
  "462037",
  "462038",
  "462039",
  "462040",
  "462041",
  "462042",
  "462043",
  "462044",
  "462045",
  "462046",
  "462047",
  "464001",
  "464661",
  "470001",
  "470002",
  "470003",
  "470004",
  "470005",
  "473001",
  "474001",
  "474002",
  "474003",
  "474004",
  "474005",
  "474006",
  "474007",
  "474008",
  "474009",
  "474011",
  "474012",
  "474020",
  "475661",
  "481661",
  "482001",
  "482002",
  "482003",
  "482004",
  "482005",
  "482006",
  "482007",
  "482008",
  "482009",
  "482010",
  "482011",
  "482020",
  "483501",
  "486001",
  "486002",
  "486003",
  "486005",
  "490001",
  "490002",
  "490006",
  "490009",
  "490011",
  "490020",
  "490021",
  "490022",
  "490023",
  "490024",
  "490025",
  "490026",
  "490042",
  "491001",
  "491441",
  "492001",
  "492002",
  "492003",
  "492004",
  "492005",
  "492006",
  "492007",
  "492008",
  "492009",
  "492010",
  "492011",
  "492012",
  "492013",
  "492014",
  "492015",
  "492099",
  "495001",
  "495002",
  "495003",
  "495004",
  "495005",
  "495006",
  "495007",
  "495009",
  "496001",
  "497001",
  "500001",
  "500002",
  "500003",
  "500004",
  "500005",
  "500006",
  "500007",
  "500008",
  "500009",
  "500010",
  "500011",
  "500012",
  "500013",
  "500014",
  "500015",
  "500016",
  "500017",
  "500018",
  "500019",
  "500020",
  "500022",
  "500023",
  "500024",
  "500025",
  "500026",
  "500027",
  "500028",
  "500029",
  "500030",
  "500031",
  "500032",
  "500033",
  "500034",
  "500035",
  "500036",
  "500037",
  "500038",
  "500039",
  "500040",
  "500041",
  "500042",
  "500044",
  "500045",
  "500046",
  "500047",
  "500048",
  "500049",
  "500050",
  "500051",
  "500052",
  "500053",
  "500054",
  "500055",
  "500056",
  "500057",
  "500058",
  "500059",
  "500060",
  "500061",
  "500062",
  "500063",
  "500064",
  "500065",
  "500066",
  "500067",
  "500068",
  "500069",
  "500070",
  "500071",
  "500072",
  "500073",
  "500074",
  "500075",
  "500076",
  "500077",
  "500079",
  "500080",
  "500081",
  "500082",
  "500083",
  "500084",
  "500085",
  "500086",
  "500087",
  "500088",
  "500089",
  "500090",
  "500091",
  "500092",
  "500093",
  "500094",
  "500095",
  "500096",
  "500097",
  "500098",
  "500100",
  "500102",
  "501301",
  "501505",
  "502032",
  "502103",
  "502114",
  "502210",
  "502211",
  "502220",
  "502221",
  "502228",
  "502246",
  "502249",
  "502251",
  "502257",
  "502276",
  "502277",
  "502318",
  "502321",
  "502375",
  "503001",
  "503002",
  "503003",
  "504001",
  "504309",
  "505001",
  "505002",
  "505187",
  "505208",
  "505209",
  "505215",
  "505404",
  "506001",
  "506002",
  "506003",
  "506004",
  "506005",
  "506007",
  "506009",
  "507001",
  "507002",
  "507003",
  "507101",
  "507103",
  "507115",
  "507118",
  "507119",
  "507120",
  "507154",
  "508001",
  "508207",
  "515001",
  "515002",
  "515003",
  "515004",
  "515005",
  "516001",
  "516002",
  "516003",
  "516004",
  "516005",
  "516360",
  "517501",
  "517502",
  "517503",
  "517504",
  "517505",
  "517506",
  "517507",
  "518001",
  "518002",
  "518003",
  "518004",
  "518005",
  "518006",
  "518501",
  "518502",
  "518503",
  "520001",
  "520002",
  "520003",
  "520004",
  "520005",
  "520006",
  "520007",
  "520008",
  "520009",
  "520010",
  "520011",
  "520012",
  "520013",
  "520015",
  "521104",
  "521108",
  "521134",
  "521137",
  "521139",
  "522001",
  "522002",
  "522003",
  "522004",
  "522005",
  "522006",
  "522007",
  "522008",
  "522034",
  "522201",
  "522501",
  "522503",
  "523001",
  "523002",
  "524001",
  "524002",
  "524003",
  "530001",
  "530002",
  "530003",
  "530004",
  "530007",
  "530008",
  "530009",
  "530010",
  "530011",
  "530012",
  "530013",
  "530016",
  "530017",
  "530018",
  "530020",
  "530022",
  "530024",
  "530026",
  "530027",
  "530028",
  "530029",
  "530040",
  "530041",
  "530043",
  "530044",
  "530045",
  "530047",
  "530048",
  "530051",
  "533001",
  "533002",
  "533003",
  "533004",
  "533005",
  "533006",
  "533101",
  "533103",
  "533104",
  "533105",
  "533106",
  "533242",
  "533253",
  "534001",
  "534002",
  "534003",
  "534004",
  "534005",
  "534006",
  "534007",
  "534101",
  "534102",
  "534197",
  "534211",
  "534216",
  "534218",
  "534222",
  "534301",
  "534302",
  "534303",
  "534329",
  "560001",
  "560002",
  "560003",
  "560004",
  "560005",
  "560006",
  "560007",
  "560008",
  "560009",
  "560010",
  "560011",
  "560012",
  "560013",
  "560014",
  "560015",
  "560016",
  "560017",
  "560018",
  "560019",
  "560020",
  "560021",
  "560022",
  "560023",
  "560024",
  "560025",
  "560026",
  "560027",
  "560028",
  "560029",
  "560030",
  "560031",
  "560032",
  "560033",
  "560034",
  "560035",
  "560036",
  "560037",
  "560038",
  "560039",
  "560040",
  "560041",
  "560042",
  "560043",
  "560044",
  "560045",
  "560046",
  "560047",
  "560048",
  "560049",
  "560050",
  "560051",
  "560052",
  "560053",
  "560054",
  "560055",
  "560056",
  "560057",
  "560058",
  "560059",
  "560060",
  "560061",
  "560062",
  "560063",
  "560064",
  "560065",
  "560066",
  "560067",
  "560068",
  "560069",
  "560070",
  "560071",
  "560072",
  "560073",
  "560075",
  "560076",
  "560077",
  "560078",
  "560079",
  "560080",
  "560081",
  "560083",
  "560084",
  "560085",
  "560086",
  "560087",
  "560090",
  "560091",
  "560092",
  "560093",
  "560094",
  "560095",
  "560096",
  "560097",
  "560098",
  "560099",
  "560100",
  "560102",
  "560103",
  "560104",
  "560105",
  "560108",
  "560109",
  "562123",
  "562125",
  "562162",
  "570001",
  "570002",
  "570004",
  "570006",
  "570007",
  "570008",
  "570011",
  "570012",
  "570015",
  "570016",
  "570017",
  "570018",
  "570020",
  "570022",
  "570023",
  "570030",
  "575001",
  "575002",
  "575003",
  "575004",
  "577001",
  "577002",
  "577003",
  "577004",
  "577005",
  "577006",
  "580020",
  "580021",
  "580022",
  "580023",
  "580024",
  "580027",
  "580028",
  "580029",
  "580030",
  "580031",
  "580032",
  "583101",
  "583102",
  "583103",
  "583104",
  "585101",
  "585102",
  "585103",
  "585104",
  "585105",
  "585401",
  "585402",
  "585403",
  "586101",
  "586102",
  "586103",
  "586104",
  "586106",
  "586107",
  "586109",
  "590001",
  "590002",
  "590003",
  "590004",
  "590005",
  "590006",
  "590007",
  "590008",
  "590009",
  "590010",
  "590011",
  "590012",
  "590013",
  "590014",
  "590015",
  "590016",
  "590019",
  "591307",
  "591312",
  "600001",
  "600002",
  "600003",
  "600004",
  "600005",
  "600006",
  "600007",
  "600008",
  "600010",
  "600011",
  "600012",
  "600013",
  "600014",
  "600015",
  "600016",
  "600017",
  "600018",
  "600019",
  "600020",
  "600021",
  "600022",
  "600023",
  "600024",
  "600026",
  "600027",
  "600028",
  "600029",
  "600030",
  "600031",
  "600032",
  "600033",
  "600034",
  "600035",
  "600036",
  "600037",
  "600038",
  "600039",
  "600040",
  "600041",
  "600042",
  "600043",
  "600044",
  "600045",
  "600046",
  "600047",
  "600048",
  "600049",
  "600050",
  "600053",
  "600054",
  "600055",
  "600056",
  "600058",
  "600059",
  "600060",
  "600061",
  "600062",
  "600063",
  "600064",
  "600069",
  "600070",
  "600071",
  "600072",
  "600073",
  "600074",
  "600075",
  "600076",
  "600077",
  "600078",
  "600079",
  "600080",
  "600081",
  "600082",
  "600083",
  "600084",
  "600085",
  "600086",
  "600087",
  "600088",
  "600089",
  "600090",
  "600091",
  "600092",
  "600093",
  "600094",
  "600095",
  "600096",
  "600097",
  "600099",
  "600100",
  "600101",
  "600102",
  "600104",
  "600106",
  "600107",
  "600108",
  "600109",
  "600110",
  "600112",
  "600113",
  "600114",
  "600115",
  "600116",
  "600117",
  "600118",
  "600119",
  "600122",
  "600125",
  "600128",
  "600129",
  "600130",
  "603001",
  "603002",
  "603101",
  "603103",
  "603113",
  "603202",
  "603203",
  "603209",
  "605001",
  "605002",
  "605003",
  "605004",
  "605005",
  "605006",
  "605008",
  "605009",
  "605010",
  "605013",
  "612001",
  "620001",
  "620002",
  "620003",
  "620005",
  "620006",
  "620008",
  "620009",
  "620010",
  "620012",
  "620017",
  "620018",
  "620020",
  "620021",
  "620023",
  "621703",
  "623501",
  "623502",
  "623503",
  "623504",
  "625001",
  "625002",
  "625003",
  "625007",
  "625009",
  "625010",
  "625011",
  "625012",
  "625013",
  "625014",
  "625016",
  "625017",
  "625018",
  "625020",
  "625402",
  "625501",
  "629001",
  "629002",
  "629003",
  "629004",
  "635109",
  "635110",
  "635126",
  "636001",
  "636002",
  "636003",
  "636004",
  "636005",
  "636006",
  "636007",
  "636008",
  "636009",
  "636352",
  "636701",
  "636704",
  "636705",
  "636803",
  "636804",
  "636807",
  "638001",
  "638002",
  "638003",
  "638004",
  "638005",
  "638009",
  "638010",
  "638011",
  "638012",
  "638107",
  "638112",
  "639001",
  "639002",
  "639003",
  "639004",
  "639005",
  "639006",
  "641001",
  "641002",
  "641004",
  "641005",
  "641006",
  "641009",
  "641011",
  "641012",
  "641013",
  "641014",
  "641015",
  "641017",
  "641018",
  "641025",
  "641027",
  "641028",
  "641029",
  "641030",
  "641031",
  "641033",
  "641034",
  "641035",
  "641036",
  "641037",
  "641044",
  "641045",
  "641048",
  "641049",
  "641103",
  "678001",
  "678002",
  "678003",
  "678004",
  "678005",
  "678006",
  "678007",
  "678008",
  "678010",
  "678012",
  "678013",
  "682020",
  "682024",
  "682026",
  "682030",
  "682032",
  "686001",
  "686002",
  "686003",
  "686004",
  "700002",
  "700003",
  "700004",
  "700005",
  "700006",
  "700007",
  "700008",
  "700009",
  "700010",
  "700011",
  "700013",
  "700014",
  "700015",
  "700016",
  "700019",
  "700025",
  "700026",
  "700027",
  "700028",
  "700029",
  "700030",
  "700031",
  "700032",
  "700033",
  "700034",
  "700035",
  "700036",
  "700037",
  "700038",
  "700039",
  "700040",
  "700041",
  "700042",
  "700045",
  "700046",
  "700047",
  "700048",
  "700049",
  "700050",
  "700051",
  "700052",
  "700053",
  "700054",
  "700055",
  "700056",
  "700057",
  "700058",
  "700059",
  "700060",
  "700061",
  "700062",
  "700063",
  "700064",
  "700068",
  "700070",
  "700071",
  "700074",
  "700075",
  "700076",
  "700077",
  "700078",
  "700079",
  "700080",
  "700081",
  "700082",
  "700083",
  "700084",
  "700085",
  "700086",
  "700088",
  "700089",
  "700090",
  "700091",
  "700092",
  "700093",
  "700094",
  "700095",
  "700096",
  "700097",
  "700098",
  "700099",
  "700101",
  "700102",
  "700103",
  "700104",
  "700105",
  "700106",
  "700107",
  "700108",
  "700109",
  "700110",
  "700111",
  "700112",
  "700113",
  "700114",
  "700115",
  "700116",
  "700117",
  "700118",
  "700119",
  "700120",
  "700121",
  "700122",
  "700123",
  "700124",
  "700125",
  "700126",
  "700127",
  "700128",
  "700129",
  "700130",
  "700131",
  "700132",
  "700133",
  "700135",
  "700144",
  "700145",
  "700146",
  "700147",
  "700148",
  "700149",
  "700150",
  "700151",
  "700152",
  "700153",
  "700154",
  "700155",
  "700156",
  "700157",
  "700159",
  "711101",
  "711102",
  "711103",
  "711104",
  "711105",
  "711106",
  "711107",
  "711108",
  "711109",
  "711110",
  "711111",
  "711112",
  "711202",
  "711204",
  "711302",
  "711304",
  "711309",
  "711313",
  "711315",
  "711316",
  "711403",
  "712101",
  "712102",
  "712103",
  "712104",
  "712123",
  "712124",
  "712125",
  "712136",
  "712139",
  "712222",
  "712401",
  "712403",
  "712410",
  "713101",
  "713102",
  "713103",
  "713104",
  "713201",
  "713202",
  "713203",
  "713204",
  "713205",
  "713206",
  "713207",
  "713208",
  "713209",
  "713210",
  "713211",
  "713212",
  "713213",
  "713214",
  "713215",
  "713216",
  "713217",
  "713218",
  "713301",
  "713302",
  "713303",
  "713304",
  "713325",
  "713333",
  "713343",
  "713409",
  "721101",
  "721301",
  "721302",
  "721303",
  "721304",
  "721305",
  "721306",
  "721423",
  "721428",
  "721441",
  "722101",
  "722102",
  "722155",
  "723101",
  "723102",
  "723103",
  "731204",
  "731235",
  "731236",
  "734001",
  "734004",
  "734006",
  "741101",
  "741121",
  "741201",
  "741235",
  "741245",
  "741248",
  "741402",
  "741404",
  "741502",
  "743124",
  "743125",
  "743126",
  "743134",
  "743135",
  "743144",
  "743145",
  "743165",
  "743222",
  "743233",
  "743234",
  "743248",
  "743268",
  "743387",
  "751001",
  "751002",
  "751003",
  "751004",
  "751005",
  "751006",
  "751007",
  "751008",
  "751009",
  "751010",
  "751011",
  "751012",
  "751013",
  "751014",
  "751015",
  "751016",
  "751017",
  "751018",
  "751019",
  "751020",
  "751021",
  "751022",
  "751023",
  "751024",
  "751025",
  "751029",
  "751030",
  "751031",
  "752001",
  "752002",
  "752055",
  "752106",
  "753001",
  "753002",
  "753003",
  "753004",
  "753005",
  "753006",
  "753007",
  "753008",
  "753009",
  "753010",
  "753011",
  "753012",
  "753013",
  "753014",
  "753015",
  "754005",
  "754021",
  "755019",
  "756019",
  "756020",
  "757001",
  "757025",
  "757032",
  "757034",
  "757041",
  "757043",
  "758001",
  "758002",
  "760001",
  "760002",
  "760003",
  "760004",
  "760005",
  "760006",
  "760008",
  "760009",
  "760010",
  "767001",
  "767002",
  "768001",
  "768002",
  "768003",
  "768004",
  "768005",
  "768006",
  "768028",
  "768201",
  "768202",
  "769001",
  "769002",
  "769003",
  "769004",
  "769005",
  "769006",
  "769008",
  "769009",
  "769010",
  "769012",
  "769013",
  "769014",
  "769015",
  "769042",
  "781001",
  "781003",
  "781004",
  "781005",
  "781006",
  "781007",
  "781008",
  "781009",
  "781010",
  "781011",
  "781012",
  "781013",
  "781014",
  "781015",
  "781016",
  "781017",
  "781018",
  "781019",
  "781020",
  "781021",
  "781022",
  "781024",
  "781025",
  "781026",
  "781028",
  "781029",
  "781032",
  "781033",
  "781034",
  "781035",
  "781036",
  "781037",
  "781038",
  "781040",
  "781301",
  "781302",
  "781309",
  "781314",
  "781316",
  "781319",
  "783301",
  "783324",
  "783325",
  "783331",
  "785001",
  "785006",
  "785015",
  "785104",
  "785680",
  "799001",
  "799002",
  "799003",
  "799004",
  "799005",
  "799006",
  "799007",
  "799009",
  "799010",
  "799011",
  "799012",
  "799014",
  "800001",
  "800002",
  "800003",
  "800004",
  "800005",
  "800006",
  "800007",
  "800008",
  "800010",
  "800011",
  "800012",
  "800013",
  "800014",
  "800015",
  "800016",
  "800017",
  "800018",
  "800019",
  "800020",
  "800021",
  "800022",
  "800023",
  "800024",
  "800025",
  "800026",
  "800027",
  "800029",
  "801103",
  "801104",
  "801105",
  "801108",
  "801111",
  "801115",
  "801503",
  "801505",
  "802301",
  "803101",
  "803118",
  "811201",
  "811202",
  "811214",
  "811310",
  "811311",
  "812001",
  "812002",
  "812003",
  "812004",
  "812005",
  "812006",
  "812007",
  "813210",
  "813233",
  "814112",
  "814113",
  "814114",
  "814116",
  "814133",
  "814142",
  "814143",
  "814147",
  "814153",
  "821113",
  "821115",
  "823001",
  "823002",
  "825301",
  "825302",
  "825303",
  "825409",
  "826001",
  "826003",
  "826004",
  "826005",
  "827001",
  "827003",
  "827004",
  "827006",
  "827009",
  "827012",
  "827013",
  "827014",
  "828106",
  "828111",
  "828127",
  "831001",
  "831002",
  "831003",
  "831004",
  "831005",
  "831006",
  "831007",
  "831009",
  "831011",
  "831012",
  "831013",
  "831017",
  "831018",
  "831019",
  "831020",
  "832110",
  "834001",
  "834002",
  "834003",
  "834004",
  "834005",
  "834006",
  "834008",
  "834009",
  "835221",
  "835222",
  "841226",
  "841227",
  "841301",
  "842001",
  "842002",
  "842003",
  "842004",
  "842005",
  "843108",
  "843109",
  "843113",
  "843301",
  "843302",
  "844101",
  "845401",
  "845438",
  "845459",
  "846001",
  "846002",
  "846003",
  "846004",
  "846008",
  "846009",
  "847211",
  "847212",
  "847306",
  "848101",
  "848102",
  "848121",
  "848122",
  "848127",
  "848130",
  "848202",
  "848210",
  "851101",
  "851117",
  "851204",
  "851205",
  "851212",
  "851213",
  "851214",
  "852107",
  "852201",
  "852212",
  "854102",
  "854103",
  "854105",
  "854106",
  "854109",
  "854301",
  "854302",
  "854311",
  "855107",
  "855108",

  // ... all your pincodes
];
