import React from 'react'

const TotalRevenue = () => {
  return (
    <div>
      <h1>total revenue</h1>
    </div>
  )
}

export default TotalRevenue
