import populartest01 from "../Images/test-image-01.avif";
import womenhealth from "../Images/womanhealthpackage.jpg";
import menhealth from "../Images/MaleHealthPackage.jpg";
import GenaralHealthPackage from "../Images/GenaralHealthPackage.jpg";
import FemaleAdvancePackage from "../Images/FemaleAdvancePackage.jpg";
import maleadvancepackage from "../Images/maleadvancepackage.jpg";
import FemaleSeniorCitizen from "../Images/FemaleSeniorCitizen.jpg";
import seniorcitizen from "../Images/seniorcitizen.jpg";

export const healthPackagesArray = [
  {
    id: "01",
    productName: "AAROGYAM A (41 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 Tests)",
        tests: [
          "APOLIPOPROTEIN - B (APO-B)",
          "APO B / APO A1 RATIO(APO B/A1)",
          "LIPOPROTEIN (a) [Lp(a)]",
          "HIGH SENSITIVITY C-REACTIVE PROTEIN (HS-CRP)",
          "APOLIPOPROTEIN - A1 (APO-A1)",
        ],
      },
    ],
    desc: "Tests Includes Lipid, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2999.0,
    price: 1499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description: "AAROGYAM A.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM A",
    overlayDetails: [
      "Lipid (10)",
      "Thyroid (24)",
      "Liver (2)",
      "Renal (4)",
      "Iron Deficiency (10)",
      "Cardiac Risk Markers (41)",
    ],
    avgRating: 4.5,
  },

  {
    id: "02",
    productName: "AAROGYAM B (71 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 Tests)",
        tests: [
          "APOLIPOPROTEIN - B (APO-B)",
          "APO B / APO A1 RATIO(APO B/A1)",
          "LIPOPROTEIN (a) [Lp(a)]",
          "HIGH SENSITIVITY C-REACTIVE PROTEIN (HS-CRP)",
          "APOLIPOPROTEIN - A1 (APO-A1)",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "LDL CHOLESTEROL - DIRECT",
          "LDL / HDL RATIO",
          "NON-HDL CHOLESTEROL",
          "VLDL CHOLESTEROL RATIO",
          "TRIG / HDL RATIO",
          "TC / HDL CHOLESTEROL RATIO",
          "HDL / LDL RATIO",
          "HDL CHOLESTEROL - DIRECT",
          "TRIGLYCERIDES",
          "TOTAL CHOLESTEROL",
        ],
      },

      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "TOTAL THYROXINE (T4)",
          "TSH - ULTRASENSITIVE",
          "TOTAL TRIIODOTHYRONINE (T3)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["HbA1c", "AVERAGE BLOOD GLUCOSE (ABG)"],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "SERUM GLOBULIN",
          "GAMMA GLUTAMYL TRANSFERASE(GGT)",
          "BILIRUBIN-TOTAL",
          "ALANINE TRANSAMINASE (SGPT)",
          "BILIRUBIN-DIRECT",
          "ALKALINE PHOSPHATESE",
          "PROTEIN-TOTAL",
          "SERUM ALB/GLOBULIN RATIO",
          "SGOT/SGPT RATIO",
          "BILIRUBIN (INDIRECT)",
          "ALBUMIN-SERUM",
          "ASPARTATE AMINOTRANSFERASE (SGOT)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "BUN / Sr.CREATININE RATIO",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "UREA / SR.CREATININE RATIO",
          "Urea (calculated)",
          "Uric Acid",
        ],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Iron Deficiency, Lipid, Liver, Renal, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3498.0,
    price: 1749.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description: "AAROGYAM B",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM B",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "03",
    productName: "AAROGYAM C (74 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (28)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["HbA1c", "AVERAGE BLOOD GLUCOSE (ABG)"],
      },
      {
        categoryName: "HORMONE (Includes 1 Tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "LDL CHOLESTEROL - DIRECT",
          "LDL / HDL RATIO",
          "NON-HDL CHOLESTEROL",
          "VLDL CHOLESTEROL RATIO",
          "TRIG / HDL RATIO",
          "TC / HDL CHOLESTEROL RATIO",
          "HDL / LDL RATIO",
          "HDL CHOLESTEROL - DIRECT",
          "TRIGLYCERIDES",
          "TOTAL CHOLESTEROL",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "SERUM GLOBULIN",
          "GAMMA GLUTAMYL TRANSFERASE(GGT)",
          "BILIRUBIN-TOTAL",
          "ALANINE TRANSAMINASE (SGPT)",
          "BILIRUBIN-DIRECT",
          "ALKALINE PHOSPHATESE",
          "PROTEIN-TOTAL",
          "SERUM ALB/GLOBULIN RATIO",
          "SGOT/SGPT RATIO",
          "BILIRUBIN (INDIRECT)",
          "ALBUMIN-SERUM",
          "ASPARTATE AMINOTRANSFERASE (SGOT)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "BUN / Sr.CREATININE RATIO",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "UREA / SR.CREATININE RATIO",
          "Urea (calculated)",
          "Uric Acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "TOTAL THYROXINE (T4)",
          "TSH - ULTRASENSITIVE",
          "TOTAL TRIIODOTHYRONINE (T3)",
        ],
      },
      {
        categoryName: "Vitmains (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Hormone, Iron Deficiency, Lipid, Liver, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5098.0,
    price: 2549.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM C",
    overlayDetails: ["Cardiac Risk Markers (5)","Complete Hemogram (28)","Diabetes (2)","Hormone (1)","Iron Deficiency (4)","Lipid (10)","Liver (12)","Renal (7)","Thyroid (3)","Vitamins (2)"],
    avgRating: 4.5,
  },

  {
    id: "04",
    productName: "AAROGYAM 1.1 (63 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Iron Deficiency, Lipid, Liver, Renal, Thyroid, Toxic Elements",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3198.0,
    price: 1599.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
        "Fortune Blood Test offers an Aarogyam 1.1 profile which is a diagnostic package that enables early diagnosis of disorders necessary for effective and timely treatment. Individuals who are in their late 40s and 50s are advised to take up the Aarogyam 1.1 package which consists of a total of 62 such Lipid Profile, UTSH, etc test parameters that aid in monitoring their health conditions and keeping themselves away from any serious illness. To help you with affordable health checkups, Thyrocare provides Aarogyam 1.1 Profile.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.1",
    overlayDetails: [
      "Cardiac Risk Markers (Includes 6 Tests)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
      "Toxic Elements (22)",
    ],
    avgRating: 4.5,
  },
  {
    id: "05",
    productName: "AAROGYAM 1.2 (93 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 Tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Iron Deficiency, Lipid, Liver, Renal, Thyroid, Toxic Elements",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
        "We believe, rather than worrying about disease conditions and treatment costs, it is important to shift the focus towards preventive checkups. Aarogyam 1.2 profile offered by Thyrocare is one such diagnostic health check-up package that aids in monitoring your health conditions, thereby preventing any lifestyle-related or metabolic health issues. This Aarogyam 1.2 profile includes 83 test parameters such as Total Triiodothyronine (T3) at a very reasonable price. Aarogyam 1.2 profile provided by Thyrocare offers all of the Aarogyam 1.1 and a few additional profiles like Diabetic Screening and a Complete Hemogram, which enables you to keep an eye on your sugar levels and infections. Book this Aarogyam 1.2 Profile from Thyrocare at a very low cost and get home collection services.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.2",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
      "Toxic Elements (22)",
    ],
    avgRating: 4.5,
  },

  {
    id: "06",
    productName: "AAROGYAM 1.3 (100 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 Tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 8 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 6 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 2 Tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 Tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 Tests)",
        tests: ["Testosterone"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Elements, Hormone, Iron Deficiency,Lipid, Liver, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6398.0,
    price: 3199.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam 1.3 package covers a series of tests and profiles for preventing risk arising from nutritional deficiencies, lifestyle, and growth. Aarogyam 1.3 consists of basic health check-up profiles that include 96 test parameters such as Thyroid profile, Iron deficiency profile, Liver profile, Lipid profile, Kidney profile, Cardiac Risk Markers, Diabetic Screening, and Complete Hemogram. Additional profiles for bone disorders, element toxicity, and vitamins, and Thyrocare offers the Aarogyam 1.3 at the most affordable price. Sample collection at home is available.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.3",
    overlayDetails: [
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "Elements (2)",
      "Hormone (1)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (8)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "07",
    productName: "AAROGYAM 1.4 (107 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES (Includes 4 Tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 Tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "LIVER (Includes 13 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alpha-1-antitrypsin (aat)",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 8 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 6 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 2 Tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 Tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 Tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 Tests)",
        tests: ["Testosterone"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Electrolytes, Elements, Hormone, Iron Deficiency, Lipid, Liver, Pancreatic, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 7398.0,
    price: 3699.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "Aarogyam 1.4 is a package of 103 tests and profiles that steer towards a diagnosis of a severe diabetic condition, pancreatic disorders, and electrolyte balance in your body to ensure the proper functioning of the body. Aarogyam 1.4 consists of basic health check-up profiles that include Thyroid profile, Iron deficiency profile, Liver profile, Lipid profile, Kidney profile, Cardiac risk markers, Diabetic Screening, Complete Hemogram, bone disorders, element toxicity, vitamins, and testosterone. Additional tests and profiles for advanced diabetes screening, pancreatic profile, and serum electrolyte test. Avail of the service by booking the Aarogyam 1.4 Profile online at an affordable rate, at your doorstep.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.4",
    overlayDetails: [
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Diabetics (4)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hormone (1)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (13)",
      "Pancreatic (2)",
      "Renal (8)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "08",
    productName: "AAROGYAM 1.5 (110 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES (Includes 5 Tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 Tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "LIVER (Includes 13 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alpha-1-antitrypsin (aat)",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 8 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 5 Tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 6 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 3 Tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 Tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 Tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 Tests)",
        tests: ["Testosterone"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Electrolytes, Elements, Hormone, Iron Deficiency, Lipid, Liver, Pancreatic, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8398.0,
    price: 4199.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam 1.5 is an umbrella of 106 tests and profiles which tells people about their risk of disorders like prediabetes, a severe form of Anemia, and Heart Diseases, including tests required during pregnancy. Aarogyam 1.5 consists of health check-up profiles that include Thyroid Profile, Iron Deficiency Profile, Liver Profile, Lipid Profile, Kidney Profile, Cardiac Risk Markers, Diabetic Screening, Complete Hemogram, Bone Disorders, Element Toxicity, Vitamins, Testosterone, Cystatin C, Pancreas profile and Serum Electrolytes Profile. Additional tests and profiles for Insulin Fasting, Ferritin, and Folate. Book your Aarogyam 1.5 Profile at an affordable cost at your home.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.5",
    overlayDetails: [
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Diabetics (5)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hormone (1)",
      "Iron Deficiency (5)",
      "Lipid (10)",
      "Liver (13)",
      "Pancreatic (2)",
      "Renal (8)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (3)",
    ],
    avgRating: 4.5,
  },
  {
    id: "09",
    productName: "AAROGYAM 1.6 (112 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES (Includes 5 Tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 Tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "LIVER (Includes 13 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alpha-1-antitrypsin (aat)",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 8 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 5 Tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 6 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 3 Tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 Tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 Tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 Tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "ARTHRITIS (Includes 2 Tests)",
        tests: ["Anti ccp (accp)", "Anti nuclear antibodies (ana)"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Diabetes, Electrolytes, Elements, Hormone, Iron Deficiency, Lipid, Liver, Pancreatic, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8998.0,
    price: 4499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam 1.6 is one such health check-up package that allows prevention and early detection of health issues, thereby reducing the chances of getting serious health issues. Aarogyam 1.6 consists of 108 tests of basic health check-up profiles that include Thyroid Profile, Iron Deficiency Profile, Liver Profile, Lipid Profile, Kidney Profile, Cardiac Risk Markers, Diabetic Screening, Complete Hemogram, Bone Disorders, Element Toxicity, Vitamins, Testosterone, Homocysteine, Cystatin C, Pancreas Profile, Electrolyte Profile, Insulin Fasting, Ferritin, and Folate. Additional profiles include Arthritis Profile. Book this Aarogyam 1.6 Profile from Thyrocare at a very low cost and get home collection services.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.6",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Diabetics (5)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hormone (1)",
      "Iron Deficiency (5)",
      "Lipid (10)",
      "Liver (13)",
      "Pancreatic (2)",
      "Renal (8)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (3)",
    ],
    avgRating: 4.5,
  },
  {
    id: "10",
    productName: "AAROGYAM 1.7 (113 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES (Includes 5 Tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 Tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "LIVER (Includes 13 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alpha-1-antitrypsin (aat)",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 8 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 5 Tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 3 Tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 Tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 Tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 Tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "ARTHRITIS (Includes 2 Tests)",
        tests: ["Anti ccp (accp)", "Anti nuclear antibodies (ana)"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Diabetes, Electrolytes, Elements, Hormone, Iron Deficiency, Lipid, Liver, Pancreatic, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 10998.0,
    price: 5499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam 1.7 is a multidisciplinary package covering a series of 109 tests and profiles that help in screening disorders related to nutritional deficiencies, sedentary lifestyle, and growth. Thyrocare offers an Aarogyam 1.7 profile that consists of 108 parameters, at a cost-effective rate. Aarogyam 1.7 contains all of the tests included in Aarogyam 1.6, along with the Lp-PLA2 test, which aids in determining the risk of developing heart disease. Thyrocare offers the Aarogyam 1.7 at the most affordable price. Sample collection at home available.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.7",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (7)",
      "Complete Hemogram (28)",
      "Diabetics (5)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hormone (1)",
      "Iron Deficiency (5)",
      "Lipid (10)",
      "Liver (13)",
      "Pancreatic (2)",
      "Renal (8)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (3)",
    ],
    avgRating: 4.5,
  },
  {
    id: "11",
    productName: "AAROGYAM 1.8 (121 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 Tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 9 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 5 Tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 Tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 Tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 Tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 13 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alpha-1-antitrypsin (aat)",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 Tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 8 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 14 Tests)",
        tests: [
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
          "Vitamin a",
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/pyridoxal - 5 - phosphate",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
          "Vitamin e",
          "Vitamin k",
        ],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 11998.0,
    price: 5999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam 1.8 offered by Thyrocare is a customized health check-up package that includes 117 test parameters to assess your overall wellness. Aarogyam 1.8 is designed in a way to suit the requirements of the individual in being one step ahead of diagnosing disorders and managing health in a better way. Aarogyam's 1.8 profile is inclusive of the tests in Aarogyam 1.4, with a few additional tests such as the Complete Vitamin Profile, Lp-PLA2, and Insulin fasting test to identify issues related to heart and development of the body. Avail of the service by booking the Aarogyam 1.8 Profile online at an affordable rate, at your doorstep.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.8",
    overlayDetails: [
      "Cardiac Risk Markers (7)",
      "Complete Hemogram (28)",
      "Diabetics (5)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hormone (1)",
      "Iron Deficiency(4)",
      "Lipid (10)",
      "Liver (13)",
      "Pancreatic (2)",
      "Renal (8)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (14)",
    ],
    avgRating: 4.5,
  },

  {
    id: "12",
    productName: "Aarogyam Winter Basic With Utsh (68 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests) ",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
      {
        categoryName: "VITAMIN (Includes 1 tests)",
        tests: ["Vitamin b-12"],
      },
      {
        categoryName: "VITAMINS (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, IRON DEFICIENCY, LIPID, LIVER, RENAL, THYROID, VITAMIN, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2498.0,
    price: 1249.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "aarogyam winter basic with utsh",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Winter Basic With Utsh",
    overlayDetails: [
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (7)",
      "THYROID (1)",
      "VITAMIN (1)",
      "VITAMINS (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "13",
    productName: "Aarogyam Winter Advanced With Utsh (79 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 4 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
        ],
      },
      {
        categoryName: "ELEMENTS (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 8 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3498.0,
    price: 1749.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam Winter Advanced With Utsh",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Winter Advanced With Utsh",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetics (4)",
      "ELEMENTS (2)",
      "HORMONE (1)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "PANCREATIC (12)",
      "RENAL (8)",
      "THYROID (3)",
      "VITAMINS (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "14",
    productName: "Aarogyam Tax Saver - Basic With Utsh (83 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 6 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 4 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, VITAMIN ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4998.0,
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam Tax Saver - Basic With UTSH comprises 79 parameters. It contains liver, complete hemogram, diabetes, cardiac risk markers, kidney, thyroid, hormones, vitamins, and many other important tests essential to check the functioning of vital organs & identify signs and symptoms of different health conditions at an early stage. This ideal full-body health checkup package is highly recommended for all at affordable prices that help claim tax deductions up to Rs. 5000 under section 80D.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Tax Saver - Basic With Utsh",
    overlayDetails: [
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Diabetes (4)",
      "ELECTROLYTES (2)",
      "ELEMENTS (2)",
      "HORMONE (1)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (2)",
      "RENAL (7)",
      "THYROID (3)",
      "VITAMIN (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "15",
    productName: "Aarogyam Tax Saver - Advanced With Utsh (136 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "ARTHRITIS (Includes 2 tests)",
        tests: ["Anti ccp (accp)", "Anti nuclear antibodies (ana)"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 7 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fasting blood sugar(glucose)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "METABOLIC (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 10 tests)L",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "URINOGRAM (Includes 10 tests)",
        tests: [
          "Specific gravity",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Urinary glucose",
          "Urine ketone",
          "Urinary leucocytes (pus cells)",
          "Nitrite",
          "Ph",
          "Urinary protein",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 12 tests)",
        tests: [
          "Vitamin a",
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/pyridoxal - 5 - phosphate",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
          "25-oh vitamin d (total)",
          "Vitamin e",
          "Vitamin k",
        ],
      },
    ],
    desc: "Tests Includes ARTHRITIS, CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, METABOLIC, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, URINOGRAM, VITAMIN ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 9998.0,
    price: 4999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam Tax Saver - Advanced With UTSH is a preventive care full body health checkup, comprising 132 parameters. It is an enhanced version of Aarogyam Tax Saver - Basic With UTSH with additional profiles like arthritis, toxic elements, metabolic, and urinogram tests. It’s a complete health checkup package that identifies signs and symptoms of health issues and provides detailed information about an individual’s overall health status. Aarogyam Tax Saver Advanced Profile can benefit individuals who wish to undergo a health assessment and can also help with tax deductions up to Rs. 5000 under section 80D.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Tax Saver - Advanced With Utsh",
    overlayDetails: [
      "ARTHRITIS (5)",
      "CARDIAC RISK MARKERS (24)",
      "COMPLETE HEMOGRAM (2)",
      "DIABETES (4)",
      "ELECTROLYTES (10)",
      "ELEMENTS (2)",
      "HORMONE (1)",
      "IRON DEFICIENCY (5)",
      "LIPID (10)",
      "LIVER (12)",
      "METABOLIC (1)",
      "PANCREATIC (2)",
      "RENAL (10)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "URINOGRAM (10)",
      "VITAMIN (12)",
    ],
    avgRating: 4.5,
  },

  {
    id: "16",
    productName: "Aarogyam Basic 1 With Utsh (84 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, COMPLETE URINE ANALYSIS, DIABETES, LIPID, LIVER, RENAL, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1900.0,
    price: 950.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Regular health checkups are important because early diagnosis and treatment allow better patient management. Aarogyam Basic 1 is a comprehensive preventive healthcare package. It comes with 74 parameters, including Complete urine analysis, diabetes, complete hemogram, renal health, lipid profile, liver analysis, and thyroid function test(UTSH), that identify and minimize the risk factors by detecting illness at an early stage. Book Aarogyam basic 1 and avail free sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Basic 1 With Utsh",
    overlayDetails: [
      "Complete Hemogram (28)",
      "COMPLETE URINE ANALYSIS (24)",
      "DIABETES (2)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (7)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "17",
    productName: "Aarogyam Basic 2 With Utsh (90 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, COMPLETE URINE ANALYSIS, DIABETES, IRON DEFICIENCY, LIPID, LIVER, RENAL, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2100.0,
    price: 1050.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Basic 2 With Utsh",
    overlayDetails: [
      "Complete Hemogram (28)",
      "COMPLETE URINE ANALYSIS (24)",
      "DIABETES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (7)",
      "THYROID (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "18",
    productName: "Aarogyam Male With Utsh (103 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CANCER MARKERS (Includes 1 tests)",
        tests: ["Prostate specific antigen (psa)"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 1 tests)",
        tests: ["Amylase"],
      },
      {
        categoryName: "PANCREATIC (Includes 1 tests)",
        tests: ["Lipase"],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
      {
        categoryName: "VITAMINS (Includes 2 tests)",
        tests: ["Folate", "Vitamin b-12"],
      },
    ],
    desc: "Tests Includes CANCER MARKERS, CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMIN, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4800.0,
    price: 2400.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s Aarogyam Male Profile is a comprehensive set of 99 parameters for evaluating the overall health of males. It includes basic tests for determining health risks that might arise due to lifestyle, dietary habits as well as nutritional deficiencies like Complete hemogram, Iron deficiency, Kidney profile, Lipid profile, Liver profile, Thyroid profile, Vitamin profile. Along with these, the profile also contains tests for Diabetes screening and Cardiac risk markers as well as tests for assessing the levels of electrolytes, various elements, lipase, and prostate-specific antigen that aids in determining the risk as well as early detection of various ailments.Book Aarogyam Male Profile at Thyrocare with a home collection facility or walk-in at a centre near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Male With Utsh",
    overlayDetails: [
      "CANCER MARKERS (1)",
      "CARDIAC RISK MARKERS (5)",
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (3)",
      "HORMONE (1)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (1)",
      "PANCREATIC (1)",
      "RENAL (7)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "VITAMIN (1)",
      "VITAMINS (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "19",
    productName: "Aarogyam Female With Utsh (105 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 3 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 1 tests)",
        tests: ["Vitamin b-12"],
      },
      {
        categoryName: "VITAMINS (Includes 2 tests)",
        tests: ["Folate", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, HORMONE, INFERTILITY, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMIN, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3200.0,
    price: 2600.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare offers Aarogyam Female Profile, a set of 101 parameters that aid in evaluating the overall health of women. It includes basic health check-up tests like Complete hemogram, Cardiac risk markers, Diabetes Profile, Iron deficiency, Kidney profile, Lipid profile, Liver profile, Thyroid profile with UTSH, Vitamin profile that aid in evaluating risks posed due to lifestyle, dietary habits as well as nutritional deficiencies. Apart from the mentioned tests, the profile also contains tests for assessing the levels of electrolytes, various elements, lipase, and hormones like Luteinizing hormone, Follicle-stimulating hormone, and Prolactin that aid in evaluating the risk as well as early detection of various ailments.Book Aarogyam Females Profile at Thyrocare with a home collection facility or walk-in at a centre near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Female With Utsh",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "ELECTROLYTES (3)",
      "HORMONE (1)",
      "INFERTILITY (3)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (1)",
      "PANCREATIC (2)",
      "RENAL (7)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "VITAMIN (1)",
      "VITAMINS (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "20",
    productName: "Jaanch - Infertility Profile Basic (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "INFERTILITY (Includes 4 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "THYROID (Includes 4 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes INFERTILITY, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Curated by doctors and medical experts, Jaanch - Infertility Profile Basic profile with 7 crucial parameters helps you better understand the levels and functioning of important reproductive hormones. This helps you better understand if there are any potential pregnancy or infertility-related conditions that require medical attention. If you and your partner are having issues conceiving a baby, book Jaanch - Infertility Profile Basic now at an affordable price and get a FREE sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Infertility Profile Basic",
    overlayDetails: ["INFERTILITY (4)", "THYROID (4)"],
    avgRating: 4.5,
  },

  {
    id: "21",
    productName: "Jaanch - Infertility Profile Advanced (29 Tests)",
    type: "package",
    includedTests: [
      
      {
        categoryName: "HYPERTENSION (Includes 1 tests)",
        tests: ["Aldosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 4 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "STEROID (Includes 10 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
          "Testosterone",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 3 tests)",
        tests: [
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
        ],
      },
    ],
    desc: "Tests Includes HYPERTENSION, INFERTILITY, STEROID, THYROID, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8998.0,
    price: 4499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Jaanch - Infertility Profile Advanced includes 21 tests curated by experienced doctors. The profile checks the levels and functioning of important reproductive hormones. This helps you better understand if there are any potential pregnancy or infertility-related conditions that require medical attention. If you and your partner are having issues conceiving a baby, book Jaanch - Infertility Profile Advanced now at an affordable price and get a FREE sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Infertility Profile Advanced",
    overlayDetails: [
      "HYPERTENSION (1)",
      "INFERTILITY (4)",
      "STEROID (10)",
      "THYROID (3)",
      "VITAMINS (5)",
    ],
    avgRating: 4.5,
  },

  {
    id: "22",
    productName: "Jaanch - Pcod (Mini) (50 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "INFERTILITY (Includes 4 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, INFERTILITY, LIPID, PREGNANCY, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "jaanch - pcod (mini)",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Pcod (Mini)",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (6)",
      "INFERTILITY (4)",
      "LIPID (10)",
      "PREGNANCY (1)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "23",
    productName: "Jaanch - Pcod Basic (69 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 6 tests)",
        tests: [
          "17 oh progesterone",
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "RENAL (Includes 4 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Creatinine - serum",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, HORMONE, INFERTILITY, LIPID, LIVER, PREGNANCY, RENAL, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 9998.0,
    price: 4999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Jaanch - PCOD Basic profile includes 65 tests that evaluate the levels and functioning of hormones important for your reproductive health as well as assess the presence and severity of PCOD in girls and women. If you experience symptoms like irregular periods, heavy bleeding during periods, acne, excessive hair growth on the body, hair fall, weight gain, and skin darkening, book Jaanch - PCOD Basic profile now. It will help your doctor diagnose the problem and get you focused on treatment. Get a FREE home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Pcod Basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (6)",
      "HORMONE (1)",
      "INFERTILITY (6)",
      "LIPID (10)",
      "LIVER (12)",
      "PREGNANCY (1)",
      "RENAL (4)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "24",
    productName: "Jaanch - Pcod Advanced (73 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "HORMONE (Includes 2 tests)",
        tests: ["Free androgen index", "Testosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 8 tests)",
        tests: [
          "17 oh progesterone",
          "Anti mullerian hormone (amh)",
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
          "Sex hormone binding globulin (shbg)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "RENAL (Includes 4 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Creatinine - serum",
          "Uric acid",
        ],
      },
      {
        categoryName: "STEROID HORMONE (Includes 1 tests)",
        tests: ["Dihydrotestosterone (dht)"],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, HORMONE, INFERTILITY, LIPID, LIVER, PREGNANCY, RENAL, STEROID HORMONE, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 11998.0,
    price: 5999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Jaanch - PCOD Advanced includes important blood tests that help diagnose and understand the severity of PCOD in girls and women. Consisting of 69 parameters, the profile evaluates the levels of multiple hormones in your body like Follicular Stimulating Hormone (FSH), Luteinizing Hormone (LH), Prolactin and Testosterone important for maintaining reproductive health. If detected early, PCOS can be managed better to prevent severe health conditions like diabetes and abnormal blood pressure. These hormones are curated by doctors exclusively for your menstruation cycles and pregnancies.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Pcod Advanced",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (6)",
      "HORMONE (2)",
      "INFERTILITY (8)",
      "LIPID (10)",
      "LIVER (12)",
      "PREGNANCY (1)",
      "RENAL (4)",
      "STEROID HORMONE (1)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "25",
    productName: "Jaanch - Diabetic Profile Basic (73 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 5 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 9 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, IRON DEFICIENCY, LIPID, LIVER, RENAL, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4998.0,
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Jaanch - Diabetic Profile Basic helps monitor blood sugar levels and keep track of overall bodily functions. This profile, containing 69 parameters, aids in complete diabetic care with lipid profile, liver function tests, iron deficiency profile, kidney profile, and thyroid function tests. Early detection is important to minimize the risk of diabetes and manage it better. Diabetes has no cure, but you can manage it with early diagnosis, regular health checkups and timely treatment. Book now and get a FREE sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Diabetic Profile Basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (5)",
      "ELECTROLYTES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (9)",
      "THYROID (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "26",
    productName: "Jaanch - Diabetic Profile Advanced (109 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 7 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "C-peptide",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 9 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 3 tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMINS",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 9998.0,
    price: 4999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
       "Jaanch - Diabetic Profile Advanced is a doctor-curated comprehensive package of 105 parameters that assess your blood sugar levels. It includes crucial tests for cardiac risk markers, thyroid function tests, lipid/pancreas/kidney profiles, vitamins, liver function tests, complete blood count and more. Diabetes can cause multiple health problems. This profile helps keep track of your overall health and aids physicians to suggest specific treatments based on your medical condition. Book our Jaanch - Diabetic Profile Advanced now at an affordable rate and get a FREE sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Diabetic Profile Advanced",
    overlayDetails: [
      "Cardiac Risk Markers (7)",
      "Complete Hemogram (28)",
      "Diabetics (7)",
      "ELECTROLYTES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (2)",
      "RENAL (9)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "VITAMINS (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "27",
    productName: "Jaanch - Tumour Panel (Male) (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CANCER MARKERS (Includes 5 tests)",
        tests: [
          "Alpha feto protein (afp)",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
          "Free psa",
          "Prostate specific antigen (psa)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 1 tests)",
        tests: ["Percent free psa", ""],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Beta hcg"],
      },
    ],
    desc: "Tests Includes CANCER MARKERS, CARDIAC RISK MARKERS, PREGNANCY",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Jaanch - Tumour Panel (Male) gives you an accurate diagnosis of cancer at an early stage. Consisting of 7 parameters, the panel helps doctors diagnose your cancer, its specific type, plan a treatment or check the progress of treatment. You can also take this test if you have just finished your cancer treatment, if the disease runs in your family or if you are at risk of developing one due to factors like obesity, smoking, old age, and preexisting health conditions. Get Thyrocare's Jaanch - Tumour Panel (Male) at an affordable price with FREE home collection service.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Tumour Panel (Male)",
    overlayDetails: [
      "CANCER MARKERS (5)",
      "CARDIAC RISK MARKERS (1)",
      "PREGNANCY (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "28",
    productName: "Jaanch - Tumour Panel (Female) (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CANCER MARKERS (Includes 4 tests)",
        tests: [
          "Ca-125",
          "Ca 15.3",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
        ],
      },
      {
        categoryName: "OTHERS (Includes 1 tests)",
        tests: ["Alpha feto protein (afp)"],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Beta hcg"],
      },
    ],
    desc: "Tests Includes CANCER MARKERS, OTHERS, PREGNANCY",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Jaanch - Tumour Panel (Female) gives you an accurate diagnosis of cancer at an early stage. Consisting of 6 parameters, the panel helps doctors understand the specific type of cancer, plan a treatment or check the progress of treatment. You can also take this test if you have just finished your cancer treatment, if you have a family history of cancer or if you are at risk due to factors like obesity, smoking, old age, and preexisting health conditions. Get Thyrocare's Jaanch - Tumour Panel (Female) at affordable rates with FREE home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Tumour Panel (Female)",
    overlayDetails: ["CANCER MARKERS (4)", "OTHERS (1)", "PREGNANCY (1)"],
    avgRating: 4.5,
  },

  {
    id: "29",
    productName: "Jaanch Thyroid Profile - Basic (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "THYROID (Includes 5 tests)",
        tests: [
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes THYROID",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 998.0,
    price: 499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "jaanch thyroid profile - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Thyroid Profile - Basic",
    overlayDetails: ["THYROID (5)"],
    avgRating: 4.5,
  },

  {
    id: "30",
    productName: "Jaanch Thyroid Profile - Advanced (8 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "AUTOIMMUNE DISORDERS (Includes 1 tests)",
        tests: ["Tsh receptor antibodies", ""],
      },
      {
        categoryName: "AUTOIMMUNITY (Includes 1 tests)",
        tests: ["Anti thyroglobulin antibody (atg)"],
      },
      {
        categoryName: "THYROID (Includes 6 tests)",
        tests: [
          "Anti microsomal antibody (ama)",
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes AUTOIMMUNE DISORDERS, AUTOIMMUNITY, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4998.0,
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "jaanch thyroid profile - advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Thyroid Profile - Advanced",
    overlayDetails: [
      "AUTOIMMUNE DISORDERS (1)",
      "AUTOIMMUNITY (1)",
      "THYROID (6)",
    ],
    avgRating: 4.5,
  },

  {
    id: "31",
    productName: "Jaanch Healthy Heart Advanced New (53 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC (Includes 2 tests)",
        tests: [
          "Creatinine phosphokinase",
          "Creatinine phosphokinase (muscle/brain)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "HEMATOLOGY (Includes 1 tests)",
        tests: ["Troponin i heart attack risk"],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
    ],
    desc: "Tests Includes CARDIAC, CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, HEMATOLOGY, LIPID",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5998.0,
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "jaanch healthy heart advanced new",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Healthy Heart Advanced New",
    overlayDetails: [
      "CARDIAC (2)",
      "CARDIAC RISK MARKERS (7)",
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (3)",
      "HEMATOLOGY (1)",
      "LIPID (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "32",
    productName: "Jaanch Healthy Heart Basic New (48 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, LIPID",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2998.0,
    price: 1499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "jaanch healthy heart basic new",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Healthy Heart Basic New",
    overlayDetails: [
      "CARDIAC RISK MARKERS (5)",
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (3)",
      "LIPID (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "33",
    productName: "Jaanch - Std Profile Basic (12 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "HEPATITIS PANEL (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },
      {
        categoryName: "INFECTIOUS DISEASES (Includes 8 tests)",
        tests: [
          "Anti chlamydia antibody igg",
          "Anti chlamydia antibody igm",
          "Hiv i and ii",
          "Herpes simplex virus ii (hsv)-igg",
          "Hepatitis b surface antigen (hbsag)",
          "Treponema pallidum antibody (tpab)",
          "Treponema pallidum haemagglutination",
          "Vdrl (rpr) for syphilis",
        ],
      },
      {
        categoryName: "OTHERS (Includes 1 tests)",
        tests: ["Herpes simplex virus ii (hsv)-igm"],
      },
      {
        categoryName: "PREGNANCY (Includes 2 tests)",
        tests: [
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus i (hsv)-igm",
        ],
      },
    ],
    desc: "Tests Includes HEPATITIS PANEL, INFECTIOUS DISEASES, OTHERS, PREGNANCY",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4998.0,
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Jaanch - STD Profile Basic is a diagnostic package designed by doctors to diagnose the cause of sexually transmitted diseases (STDs). The test consisting of 12 crucial parameters helps identify various types of STDs, like Chlamydia, Syphilis, Herpes, Hepatitis and HIV. This package is suitable for both men and women. Timely diagnosis and immediate treatment by doctors can help lower the risk of any further serious complications.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Std Profile Basic",
    overlayDetails: [
      "HEPATITIS PANEL (1)",
      "INFECTIOUS DISEASES (8)",
      "OTHERS (1)",
      "PREGNANCY (2)",
    ],
    avgRating: 4.5,
  },

  // {
  //   id: "34",
  //   productName: "WOMEN HEALTH PACKAGE (71 Tests)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Thyroid Profile",
  //       tests: ["01", "02", "03"],
  //     },
  //     {
  //       categoryName: "Lipid Profile",
  //       tests: ["03", "04", "05", "07"],
  //     },
  //     // ... more categories can be added similarly
  //   ],
  //   imgUrl: womenhealth,
  //   category: "Basic",
  //   price: 1799.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "The WOMENS HEALTH PACKAGE consists of 71 types of blood tests, including Kidney, Liver, Thyroid, Iron, Lipid, Vitamin D, B12, Folic Acid, Hormones (important PCOD tests), CBC, and many more. This package is recommended for women aged between 16 to 50 years and also for those suffering from PCOD.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },

  // {
  //   id: "35",
  //   productName: "MALE HEALTH PACKAGE (70 Tests)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Thyroid Profile",
  //       tests: ["01", "02", "03"],
  //     },
  //     {
  //       categoryName: "Lipid Profile",
  //       tests: ["03", "04", "05", "07"],
  //     },
  //     // ... more categories can be added similarly
  //   ],
  //   imgUrl: menhealth,
  //   category: "Basic",
  //   price: 1799.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "The FORTUNE BLOOD TEST MALE HEALTH PACKAGE is a comprehensive health checkup package consisting of 70 fundamental tests to screen for the overall health status of men aged from 25 to 45 years. It includes tests for Lipid, Liver, Thyroid, Iron, Diabetic, Kidney, Complete Hemogram, Hormone, Vitamin D & B12.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },

  // {
  //   id: "36",
  //   productName: "GENERAL HEALTH PACKAGE (111)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Thyroid Profile",
  //       tests: ["01", "02", "03"],
  //     },
  //     {
  //       categoryName: "Lipid Profile",
  //       tests: ["03", "04", "05", "07"],
  //     },
  //     // ... more categories can be added similarly
  //   ],
  //   imgUrl: GenaralHealthPackage,
  //   category: "Basic",
  //   price: 2399.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "Introducing our General Health Package, meticulously crafted with 111 essential parameters for a comprehensive health screening. This package covers all aspects of general health, including Zinc, Copper, Magnesium, Folic Acid, Vitamin D and B12 levels, cholesterol, and other vital blood and urine tests. Ideal for individuals of any age group, it offers a holistic assessment to prioritize your well-being.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },

  // {
  //   id: "37",
  //   productName: "FEMALE ADVANCED HEALTH PACKAGE (108 Tests)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Thyroid Profile",
  //       tests: ["01", "02", "03"],
  //     },
  //     {
  //       categoryName: "Lipid Profile",
  //       tests: ["03", "04", "05", "07"],
  //     },
  //     // ... more categories can be added similarly
  //   ],
  //   imgUrl: FemaleAdvancePackage,
  //   category: "Basic",
  //   price: 2499.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "The FEMALE ADVANCED HEALTH PACKAGE includes 108 vital tests to screen the health status, such as Cardiac Risk Markers, Complete Hemogram, Diabetics, Iron, Lipid, Liver, Kidney, Thyroid, Vitamin, Pancreatic, Electrolytes, Ovarian Cancer, CEA (A Type of Tumour Marker) and Hormones. This package is recommended for women between the ages of 25 and 50 years old.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },

  // {
  //   id: "38",
  //   productName: "MALE ADVANCED HEALTH PACKAGE (105 Tests)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Thyroid Profile",
  //       tests: ["01", "02", "03"],
  //     },
  //     {
  //       categoryName: "Lipid Profile",
  //       tests: ["03", "04", "05", "07"],
  //     },
  //     // ... more categories can be added similarly
  //   ],
  //   imgUrl: maleadvancepackage,
  //   category: "Basic",
  //   price: 2499.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "The MALE ADVANCED HEALTH PACKAGE includes 105 vital tests to screen health status, such as Cardiac Risk Markers, Complete Hemogram, Diabetes, Iron, Lipid, Liver, Kidney, Thyroid, Vitamins, Pancreatic, Electrolytes, CEA (a type of Tumour Marker), Prostate Cancer (PSA), and Hormones. This package is recommended for men aged between 25 and 50 years.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },

  // {
  //   id: "39",
  //   productName: "FEMALE SENIOR CITIZEN HEALTH PACKAGE (108 Tests)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Thyroid Profile",
  //       tests: ["01", "02", "03"],
  //     },
  //     {
  //       categoryName: "Lipid Profile",
  //       tests: ["03", "04", "05", "07"],
  //     },
  //     // ... more categories can be added similarly
  //   ],
  //   imgUrl: FemaleSeniorCitizen,
  //   category: "Basic",
  //   price: 3499.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "The FEMALE SENIOR CITIZEN HEALTH PACKAGE consists of 108 fundamental tests for screening of health status, including Lipid, Liver, Kidney, Complete Hemogram, Diabetic screen, Iron, Thyroid, Electrolytes, Pancreas, Insulin, C-peptide, Homocysteine, Lp-PLA2 profiles, and CA 125 (Ovarian Cancer). This package is recommended for women aged above 45 years and for anyone suffering from high diabetes.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },

  // {
  //   id: "40",
  //   productName: "MALE SENIOR CITIZEN HEALTH PACKAGE (108 Tests)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Thyroid Profile",
  //       tests: ["01", "02", "03"],
  //     },
  //     {
  //       categoryName: "Lipid Profile",
  //       tests: ["03", "04", "05", "07"],
  //     },
  //   ],
  //   imgUrl: seniorcitizen,
  //   category: "Basic",
  //   price: 3499.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "The MALE SENIOR CITIZEN HEALTH PACKAGE consists of 108 fundamental tests for screening of health status, including Lipid, Liver, Kidney, Complete Hemogram, Diabetic screen, Iron, Thyroid, Electrolytes, Pancreas, Insulin, C-peptide, Homocysteine, Lp-PLA2 profiles, and PSA (Prostate Cancer). This package is recommended for men aged above 45 years and for anyone suffering from high diabetes.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   avgRating: 4.5,
  // },

  {
    id: "41",
    productName: "Aarogyam x with utsh (139 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 2 tests)",
        tests: ["Anti ccp (accp)", "Anti nuclear antibodies (ana)"],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Includes 7 tests",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 7 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fasting blood sugar(glucose)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Elements (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "Hormone (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 13 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alpha-1-antitrypsin (aat)",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "Pancreatic (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "Renal (Includes 10 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Toxic Elements (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "Urinogram (Includes 10 tests)",
        tests: [
          "Specific gravity",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Urinary glucose",
          "Urine ketone",
          "Urinary leucocytes (pus cells)",
          "Nitrite",
          "Ph",
          "Urinary protein",
        ],
      },
      {
        categoryName: "Vitamins (Includes 14 tests)",
        tests: [
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
          "Vitamin a",
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/pyridoxal - 5 - phosphate",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
          "Vitamin e",
          "Vitamin k",
        ],
      },
    ],
    imgUrl: seniorcitizen,
    category: "Basic",
    mrp: 13998.0,
    price: 6999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s Aarogyam X profile tests for 135 parameters and provides an overall snapshot of your health status. Thyrocare’s Aarogyam X profile includes all the tests covered in Aarogyam 1.8 with a few more tests like Fasting Blood Sugar, Magnesium, Electrolyte Profile, Arthritis Profile, and Urinary Microalbumin that aid in assessing your well-being and identifying a potential illness or health risks before they strike. ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "aarogyam x with utsh",
    overlayDetails: [
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "Elements (2)",
      "Hormone (1)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (8)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "42",
    productName: "Aarogyam Xl with utsh (149 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 2 tests)",
        tests: ["Anti ccp (accp)", "Anti nuclear antibodies (ana)"],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 7 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fasting blood sugar(glucose)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Hematology (Includes 1 tests)",
        tests: ["Troponin i heart attack risk"],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 13 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alpha-1-antitrypsin (aat)",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "Pancreatic (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "Renal (Includes 10 tests)",
        tests: [
          "TestosteroneBun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Steroid (Includes 10 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
          "Testosterone",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "Urinogram (Includes 10 tests)",
        tests: [
          "Specific gravity",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Urinary glucose",
          "Urine ketone",
          "Urinary leucocytes (pus cells)",
          "Nitrite",
          "Ph",
          "Urinary protein",
        ],
      },

      {
        categoryName: "Vitamin (Includes 14 tests)",
        tests: [
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
          "Vitamin a",
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/pyridoxal - 5 - phosphate",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
          "Vitamin e",
          "Vitamin k",
        ],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Diabetes, Electrolytes, Elements, Hematology, Iron Deficiency, Lipid, Liver, Metabolic, Pancreatic, Renal, Steroid, Thyroid, Toxic Elements, Urinogram, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 16998.0,
    price: 8499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Aarogyam XL profile offered by Thyrocare is a diagnostic profile of specially curated 145 test parameters to evaluate health in the best possible way. Read on to know more about the Aarogyam XL package offers a unique diagnostic profile of specialized tests to evaluate health in the best possible way. It is a complete diagnostic package of tests and profiles that aids in preventing risk arising from nutritional deficiencies, hormonal imbalance, lifestyle, and growth. This profile includes tests to assess the health of a wide range of individuals depending on their occupation, age, and gender. The Aarogyam XL profile includes all the tests covered in Aarogyam X along with a few more tests to measure the levels of steroids (hormones) in the body. Avail of the service by booking the Aarogyam XL Profile online at an affordable rate, at your doorstep.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "aarogyam xl with utsh",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (7)",
      "Complete Hemogram (28)",
      "Diabetes (2)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hematology (1)",
      "Iron Deficiency (5)",
      "Lipid (10)",
      "Liver (13)","Metabolic (1)","Pancreatic (2)",
      "Renal (10)",
      "Steroid (10)","Thyroid (3)",
      "Toxic Elements (22)","Urinogram (10)",
      "Vitamin (14)",
    ],
    avgRating: 4.5,
  },

  {
    id: "43",
    productName: "Aarogyam 24x7 (non fasting) - advanced (100 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 2 tests)",
        tests: ["Anti ccp (accp)", "Anti nuclear antibodies (ana)"],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Complete Urine Analysis (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Lipid (Includes 1 tests)",
        tests: ["Total cholesterol"],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes  Arthritis, Cardiac Risk Markers, Complete Hemogram, Complete Urine Analysis, Diabetes, Electrolytes, Lipid, Liver, Renal, Thyroid, Toxic Elements, Vitamins ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 10298.0,
    price: 5149.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Now you don't need to fast before taking a blood test. Our Aarogyam 24x7 (Non-Fasting) - Advanced Profile - is a package of 107 parameters that you can avail anytime during the day, with no fasting at all. Studies show that kidney, liver, thyroid function, blood counts etc. are not influenced by fasting. We provide an ideal test package that covers majority of blood tests needed for essential health monitoring. These tests help in detecting diseases early, including diabetes, and monitoring how well a person's body responds to treatments. Early diagnosis and prompt treatment by a doctor can greatly reduce complications. Book your test today.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "aarogyam 24x7 (non fasting) - advanced (110 Tests)",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetics (2)",
      "Electrolytes (2)",
      "Lipid (1)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "44",
    productName: "Aarogyam 24x7 (Non Fasting) - Basic",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "Complete Urine Analysis (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Lipid (Includes 1 tests)",
        tests: ["Total cholesterol"],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Lipid, Complete Hemogram, Toxic Elements, Thyroid, Diabetes, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Elements, Hormone ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5998.0,
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam 24x7 (Non Fasting) - Basic",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetics (2)",
      "Electrolytes (2)",
      "Lipid (1)",
      "Liver (12)",
      "Renal (7)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "45",
    productName: "Aarogyam Basic 1 With Utsh (84 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Complete Urine Analysis (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },

      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },

      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Complete Urine Analysis, Diabetes, Lipid, Liver, Renal, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1900.0,
    price: 950.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Regular health checkups are important because early diagnosis and treatment allow better patient management. Aarogyam Basic 1 is a comprehensive preventive healthcare package. It comes with 74 parameters, including Complete urine analysis, diabetes, complete hemogram, renal health, lipid profile, liver analysis, and thyroid function test(UTSH), that identify and minimize the risk factors by detecting illness at an early stage. Book Aarogyam basic 1 and avail free sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Basic 1 With Utsh",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Cardiac Urine Analysis (24)",
      "Diabetics (2)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "46",
    productName: "Aarogyam Camp Profile 1 (62 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },

      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Lipid, Liver, Renal, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4198.0,
    price: 2099.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "aarogyam camp profile 1",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Camp Profile 1",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "47",
    productName: "Aarogyam Camp Profile 2 (69 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },

      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },

      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Lipid, Liver, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6198.0,
    price: 3099.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "aarogyam camp profile 2",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Camp Profile 2",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)", "Vitamins (2)"
    ],
    avgRating: 4.5,
  },

  {
    id: "48",
    productName: "Advanced Full Body Checkup With Vitamins (92 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },

      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },

      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },

      {
        categoryName: "Iron Deficiency (Includes 1 tests)",
        tests: ["Iron"],
      },

      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Other Counts (Includes 1 tests)",
        tests: ["Erythrocyte sedimentation rate (esr)"],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Complete Urine Analysis, Diabetes, Electrolytes, Iron Deficiency, Lipid, Liver, Other Counts, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2998.0,
    price: 1499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Advanced Full Body Checkup with Vitamins profile is a full-body preventive health checkup that gives you complete understanding of your bodily functions. The profile comprises 92 tests including Thyroid, Liver, Lipid and Kidney profiles, Iron deficiency, Serum Electrolytes, Complete Urine Analysis and more. Additional profiles like Diabetic Screening and CBC help you monitor sugar levels and potential blood infections. Vitamin D and B12 tests help measure vitamin levels. Book this profile at an affordable rate and get a FREE home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Advanced Full Body Checkup With Vitamins",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetics (2)",
      "Electrolytes (2)",
      "Iron Deficiency (1)",
      "Lipid (10)",
      "Liver (12)",
      "Other Counts (1)",
      "Renal (7)",
      "Thyroid (3)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "49",
    productName: "Amh Advanced Profile (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes Infertility, Pregnancy, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6120.0,
    price: 3060.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "amh advanced profile",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Amh Advanced Profile",
    overlayDetails: ["Infertitlity (4)", "Pregnancy (1)", "Thyroid (1)"],
    avgRating: 4.5,
  },

  {
    id: "50",
    productName: "Amino Acid Profile (35 tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Amino Acid (Includes 35 tests)",
        tests: [
          "Alpha-aminoadipic acid",
          "Alpha-aminobutyric acid",
          "Alanine",
          "Anserine",
          "Arginine",
          "Argininosuccinic acid",
          "Asparagine",
          "Aspartic acid",
          "Beta-aminoisobutyric acid",
          "Beta-alanine",
          "Citrulline",
          "Cystathionine",
          "Cystine",
          "Gamma-aminobutyric acid",
          "Glutamine",
          "Glutamic acid",
          "Glycine",
          "Histidine",
          "Hydroxylysine",
          "Hydroxyproline",
          "Isoleucine",
          "Leucine",
          "Lysine",
          "Methionine",
          "N-methylhistidine",
          "Ornithine",
          "Phenylalanine",
          "Proline",
          "Sarcosine",
          "Serine",
          "Threonine",
          "3-methylhistidine",
          "Tryptophan",
          "Tyrosine",
          "Valine",
        ],
      },
    ],
    desc: "Tests Includes Amino Acid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4120.0,
    price: 2060.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Amino acids are the building blocks of proteins and are crucial in several bodily functions. This profile tests for 35 amino acids that help monitor their blood levels, along with identifying any inherited metabolic disorders and urea cycle defects. Get this test at an affordable price and get a better understanding of your overall health. Book now and enjoy free sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Amh Advanced Profile",
    overlayDetails: ["Amino Acid (35)"],
    avgRating: 4.5,
  },

  {
    id: "51",
    productName: "Antenatal Profile - Advanced (51 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Blood Grouping And Rh Typing (Includes 2 tests)",
        tests: ["Blood grouping", "Rh typing"],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
        ],
      },

      {
        categoryName: "Hepatitis Panel (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },

      {
        categoryName: "Infectious Diseases (Includes 3 tests)",
        tests: [
          "Hiv i and ii",
          "Hepatitis b surface antigen (hbsag)",
          "Vdrl (rpr) for syphilis",
        ],
      },

      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },

      {
        categoryName: "Thalassemia (Includes 5 tests)",
        tests: [
          "Hemoglobin a2",
          "Hemoglobin c",
          "Hemoglobin d",
          "Hemoglobin f",
          "Hemoglobin s",
        ],
      },

      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },

      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Folate", "Vitamin b-12"],
      },
    ],
    desc: "Tests Includes Blood Grouping And Rh Typing, Complete Hemogram, Diabetes, Hepatitis Panel, Infectious Diseases, Iron Deficiency, Thalassemia, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5160.0,
    price: 2580.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "antenatal profile - advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Antenatal Profile - Advanced",
    overlayDetails: [
      "Blood Grouping And Rh Typing (2)",
      "Complete Hemogram (28)",
      "Diabetes (3)",
      "Hepatitis Panel (1)",
      "Infectious Diseases (3)",
      "Iron Deficiency (3)",
      "Thalassemia (5)",
      "Thyroid (3)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "52",
    productName: "Antenatal Profile - Basic (35 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Hepatitis Panel (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },

      {
        categoryName: "Infectious Diseases (Includes 3 tests)",
        tests: [
          "Hiv i and ii",
          "Hepatitis b surface antigen (hbsag)",
          "Vdrl (rpr) for syphilis",
        ],
      },

      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Hepatitis Panel, Infectious Diseases, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3100.0,
    price: 1550.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "antenatal profile - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Antenatal Profile - Basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Hepatitis Panel (1)",
      "Infectious Diseases (3)",
      "Thyroid (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "53",
    productName: "Antenatal Profile Extended (85 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Autoimmunity (Includes 1 tests)",
        tests: ["Anti phospholipid antibody (apl) - igg"],
      },

      {
        categoryName: "Blood Grouping and Rh Typing (Includes 2 tests)",
        tests: ["Blood grouping", "Rh typing"],
      },

      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["Homocysteine"],
      },

      {
        categoryName: "Coagulation (Includes 1 tests)",
        tests: ["Antithrombin iii"],
      },

      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },

      {
        categoryName: "Diabetes (Includes 3 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
        ],
      },

      {
        categoryName: "Hepatitis Panel (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },

      {
        categoryName: "Infectious Diseases (Includes 8 tests)",
        tests: [
          "Cytomegalo virus (cmv) - igg",
          "Hiv i and ii",
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus ii (hsv)-igg",
          "Rubella - igg",
          "Hepatitis b surface antigen (hbsag)",
          "Toxo gondii - igg",
          "Vdrl (rpr) for syphilis",
        ],
      },

      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 1 tests)",
        tests: ["Cardiolipin antibody (acl) -igg"],
      },

      {
        categoryName: "Thalassemia (Includes 5 tests)",
        tests: [
          "Hemoglobin a2",
          "Hemoglobin c",
          "Hemoglobin d",
          "Hemoglobin f",
          "Hemoglobin s",
        ],
      },

      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },

      {
        categoryName: "Vitamins (Includes 3 tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Autoimmunity, Blood Grouping And Rh Typing, Cardiac Risk Markers, Coagulation, Complete Hemogram, Complete Urine Analysis, Diabetes, Hepatitis Panel,Infectious Diseases, Iron Deficiency, Pregnancy, Thalassemia, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 9280.0,
    price: 4640.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "antenatal profile extended",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Antenatal Profile Extended",
    overlayDetails: [
      "Autoimmunity (1)",
      "Blood Grouping and Rh Typing (2)",
      "Cardiac Risk Markers (1)",
      "Coagulation (1)",
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)", "Diabetes (3)",
      "Hepatitis Panel (1)",
      "Infectious Diseases (8)", "Iron Deficiency (4)", "Pregnancy (1)", "Thalassemia (5)",
      "Thyroid (3)", "Vitamins (3)"
    ],
    avgRating: 4.5,
  },

  {
    id: "54",
    productName: "Arthritis Profile (59 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 6 tests)",
        tests: [
          "Anti ccp (accp)",
          "Anti nuclear antibodies (ana)",
          "Anti streptolysin - o (aso)",
          "Complement 4 (c4)",
          "Phosphorous",
          "Rheumatoid factor (rf)",
        ],
      },

      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },

      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },

      {
        categoryName: "Hb27 (Includes 1 tests)",
        tests: ["Hla-b27"],
      },

      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: ["Alkaline phosphatase"],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
      {
        categoryName: "Vitamin (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Diabetes, Hb27, Lipid, Liver, Renal, Thyroid, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 10000.0,
    price: 5000.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s arthritis profile covers 55 parameters which help detect problems with your bones and joints. You should book the profile if you are experiencing joint pain, knee problems, stiffness, etc. This profile also helps understand the type of arthritis. Book online and get a free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Arthritis Profile",
    overlayDetails: [
      "Arthritis (6)",
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "Hb27 (1)",
      "Lipid (10)",
      "Liver (1)",
      "Renal (7)",
      "Thyroid (1)",
      "Vitamin (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "55",
    productName: "Bone Profile - Advanced (13 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 4 tests)",
        tests: [
          "Anti ccp (accp)",
          "Anti nuclear antibodies (ana)",
          "Phosphorous",
          "Rheumatoid factor (rf)",
        ],
      },

      {
        categoryName: "Elements (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },

      {
        categoryName: "Liver (Includes 1 tests)",
        tests: ["Alkaline phosphatase"],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "Renal (Includes 3 tests)",
        tests: ["Calcium", "Intact parathyroid hormone (pth)", "Uric acid"],
      },
      {
        categoryName: "Vitamin (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Arthritis, Elements, Liver, Metabolic, Renal, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6600.0,
    price: 3300.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Bone Profile - Advanced checks the health of your bones and joints. It measures the levels of vitamins and minerals required for healthy bones. It also determines uric acid levels as uncontrolled levels may lead to bone issues. Book the Bone Profile - Advanced now at the best rate and get a free home collection service.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Bone Profile - Advanced",
    overlayDetails: [
      "Arthritis (4)",
      "Elements (2)",
      "Liver (2)",
      "Metabolic (1)",
      "Renal (3)",
      "Vitamin (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "56",
    productName: "Bone Profile - Basic (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 1 tests)",
        tests: ["Phosphorous"],
      },

      {
        categoryName: "Elements (Includes 1 tests)",
        tests: ["Serum zinc"],
      },

      {
        categoryName: "Liver (Includes 1 tests)",
        tests: ["Alkaline phosphatase"],
      },
      {
        categoryName: "Renal (Includes 2 tests)",
        tests: ["Calcium", "Intact parathyroid hormone (pth)"],
      },
      {
        categoryName: "Vitamin (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Arthritis, Elements, Liver, Renal, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4540.0,
    price: 2270.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Bone Profile - Basic checks the health of our bones and joints as well as identifies conditions that affect our bone health. It tests for levels of vitamin D and minerals that are essential for healthy bones. Book now at the best rate and get a free home collection service.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "bone profile - basic",
    overlayDetails: [
      "Arthritis (1)",
      "Elements (1)",
      "Liver (1)",
      "Renal (2)",
      "Vitamin (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "57",
    productName: "Cardiac Risk Markers (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2400.0,
    price: 1200.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Cardiac Risk Markers Profile estimates the levels of cardiac risk markers in the body, which help in evaluating the functioning of the heart. The test also evaluates Lipids in the body, abnormal levels of which can lead to heart attacks or other severe heart conditions. The Cardiac Risk Markers Profile is helpful to individuals who have a family history of cardiac disease or where there is an increase in the prevalence of cardiac disease at a young age. Early diagnosis of cardiac disorder can help in the overall prognosis. Thyrocare has priced the profile at just Rs. 1200/- to make it affordable to one and all. Get tested with a cardiac risk marker test at an affordable cost at your home.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "cardiac risk markers",
    overlayDetails: ["cardiac risk markers (5)"],
    avgRating: 4.5,
  },

  {
    id: "58",
    productName: "Chikungunya Rapid Test (igg/igm) (2 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Fever (Includes 2 tests)",
        tests: ["Chikungunya - igg rapid test", "Chikungunya - igm rapid test"],
      },
    ],
    desc: "Tests Includes Fever",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1040.0,
    price: 520.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Chikungunya Rapid Test (IgG/IgM) detects IgG and IgM antibodies specific to Chikungunya virus. This test helps in identifying both recent and past infections by distinguishing between IgM antibodies, which indicate a current infection, and IgG antibodies, which suggest a past infection. Book test at affordable rates. Lab visits and FREE home sample collection available. Get accurate and timely diagnosis in less than 6 hours for correct treatment.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "chikungunya rapid test (igg/igm)",
    overlayDetails: ["Fever (2)"],
    avgRating: 4.5,
  },

  {
    id: "59",
    productName: "Complete Health Check With Vitamins (122 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cancer Markers (Includes 1 tests)",
        tests: ["Carcino embryonic antigen (cea)"],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },

      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },

      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },

      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },

      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },

      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Cancer Markers, Cardiac Risk Markers, Complete Hemogram, Complete Urine Analysis, Diabetes, Electrolytes, Iron Deficiency, Lipid, Liver, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "complete health check with vitamins",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "complete health check with vitamins",
    overlayDetails: [
      "Cancer Markers (1)",
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetics (2)",
      "Electrolytes (2)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "60",
    productName: "Complete Thyroid Checkup (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid (Includes 5 tests)",
        tests: [
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1550.0,
    price: 775.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyroid gland plays a major role in many bodily functions. Regular thyroid screening is important as thyroid diseases often go undiagnosed. Our Complete Thyroid Care profile covers key parameters like TSH along with free and total T3 and T4. Book now at affordable rates + free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "complete thyroid checkup",
    overlayDetails: ["Throid (5)"],
    avgRating: 4.5,
  },

  {
    id: "61",
    productName: "Complete Urine Analysis (24 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
    ],
    desc: "Tests Includes COMPLETE URINE ANALYSIS ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1020.0,
    price: 510.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "complete urine analysis",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "complete urine analysis",
    overlayDetails: ["complete urine analysis (24)"],
    avgRating: 4.5,
  },

  {
    id: "62",
    productName: "Complete Vitamins Profile (14 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Vitamins (Includes 14 tests)",
        tests: [
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
          "Vitamin a",
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/pyridoxal - 5 - phosphate",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
          "Vitamin e",
          "Vitamin k",
        ],
      },
    ],
    desc: "Tests Includes Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 10000.0,
    price: 5000.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Complete Vitamin Profile test is a multidisciplinary package that covers both Fat-Soluble Vitamins and Water-Soluble Vitamins tests to detect a deficiency or excess vitamins required by your body to eliminate health risks, build immunity, and maintain your overall health. Abnormal levels of these vitamins can give rise to lethargy, irritation, muscle spasm, fatigue, anemia, psychological disorders, toxicity, and many more if not given timely attention. Complete vitamin profile is now at the best price with a home collection facility from Thyrocare.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "complete vitamins profile",
    overlayDetails: ["Vitamin (14)"],
    avgRating: 4.5,
  },

  {
    id: "63",
    productName: "Covid Antibody - 1 (1 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Infectious Diseases (Includes 1 tests)",
        tests: ["Covid antibody igg - elisa"],
      },
    ],
    desc: "Tests Includes Infectious Diseases",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5998.0,
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "CAntibodies 1 or COVID antibodies 1 profile offered by Thyrocare is a test for knowing the presence of antibodies in the body against the SARS-CoV-2 virus. Antibodies are developed by the body as a response when exposed to a virus or a viral protein called an antigen. The IgG antibody renders long-term protection and gives an assurance of protection against future viral encounters. The test is recommended for both healthy as well as COVID-19 recovered individuals. Thyrocare has priced the test at just Rs. 400 to make it affordable to one and all.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "covid antibody - 1",
    overlayDetails: ["Covid antibody igg - elisa (1)"],
    avgRating: 4.5,
  },

  {
    id: "64",
    productName: "Covid Infection Monitoring (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: ["C-reactive protein (crp)", "Lactate dehydrogenase (ldh)"],
      },
      {
        categoryName: "Coagulation (Includes 1 tests)",
        tests: ["D-dimer"],
      },
      {
        categoryName: "Infection (Includes 1 tests)",
        tests: ["Interleukin-6"],
      },
      {
        categoryName: "Iron Deficiency (Includes 1 tests)",
        tests: ["Ferritin"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Coagulation, Infection, Iron Deficiency",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4120.0,
    price: 2060.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "COVID Infection Monitoring Profile includes all curated tests like D-dimer, Interleukin-6, C-reactive protein, Ferritin, and Lactate dehydrogenase. These tests can help in tracking the overall health during an infection, and check for therapeutic response, disease progression, and disease consequences on other organs by monitoring key biomarkers. Book a COVID Infection Monitoring Profile at Thyrocare with a home collection facility or walk-in at a center near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "covid infection monitoring",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Coagulation (1)",
      "Infection (1)",
      "Iron Deficiency (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "65",
    productName: "Covid Infection Monitoring - Advanced (39 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "C-reactive protein (crp)",
          "Lactate dehydrogenase (ldh)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "Coagulation (Includes 1 tests)",
        tests: ["D-dimer"],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Infection (Includes 1 tests)",
        tests: ["Interleukin-6"],
      },
      {
        categoryName: "Iron Deficiency (Includes 1 tests)",
        tests: ["Ferritin"],
      },
      {
        categoryName: "Liver (Includes 4 tests)",
        tests: [
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Other Counts (Includes 1 tests)",
        tests: ["Erythrocyte sedimentation rate (esr)"],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Creatinine - serum"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Coagulation, Complete Hemogram, Infection, Iron Deficiency, Liver, Other Counts, Renal",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4740.0,
    price: 2370.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "COVID Infection Monitoring Advanced is an advanced profile with a comprehensive range of tests including D-dimer, Interleukin-6, C-reactive protein, Ferritin, and Lactate dehydrogenase, Complete hemogram, Aspartate aminotransferase, Alanine transaminase, Gamma-glutamyltransferase, Creatinine - serum, and Erythrocyte sedimentation rate. These tests will keep you reassured about your overall health and keep you from feeling overwhelmed or anxious during the course of the infection. These tests monitor the levels of essential biomarkers that may signal secondary infection or inflammation, as well as disease status, therapeutic response, and disease progression.  Book a COVID Infection Monitoring Advanced Profile at Thyrocare with a home collection facility or walk-in at a center near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "covid infection monitoring - advanced",
    overlayDetails: [
      "Cardiac Risk Markers (2)",
      "Coagulation (1)",
      "Complete Hemogram (28)",
      "Infection (1)",
      "Iron Deficiency (1)",
      "Liver (4)",
      "Other Counts (1)",
      "Renal (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "66",
    productName: "Covid-19 (1 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "- (Includes 5 tests)",
        tests: ["Covid-19 rt pcr"],
      },
    ],
    desc: "Tests Includes covid-19",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 800.0,
    price: 400.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "COVID-19 is a contagious respiratory illness caused by SARS-CoV-2 coronavirus. Common symptoms include fever, chills, cough, sore throat, difficulty breathing, fatigue, loss of smell and taste, congestion or runny nose. The test is performed by collecting a throat and nasal sample using a cotton swab. The tests accurately detect and help manage COVID-19 infection and also track your recovery post infection. Book now at affordable rates and get free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "covid-19",
    overlayDetails: ["Covid-19 rt pcr (1)"],
    avgRating: 4.5,
  },

  {
    id: "67",
    productName: "Doa Panel-6 (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 6 tests)",
        tests: [
          "Amphetamine",
          "Barbiturates",
          "Benzodiazepines",
          "Cocaine",
          "Opiates",
          "Tetrahydrocannabinol",
        ],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1640.0,
    price: 820.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s drug panel 6 includes qualitative tests that use a urine sample to evaluate the presence of any drug abuse. This panel covers most popular illicit drugs, namely amphetamine, barbiturates, benzodiazepines, cocaine, opiates and tetrahydrocannabinol, to evaluate and detect the presence of drug overdose or intoxication. To get this panel at the best price, walk in at our nearest centre or opt for home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "doa panel-6",
    overlayDetails: ["Drug of Abuse (6)"],
    avgRating: 4.5,
  },

  {
    id: "68",
    productName: "Doctor Recommended Full Body Checkup Advanced (92 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Iron Deficiency (Includes 1 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Complete Urine Analysis, Diabetes, Iron Deficiency, Lipid, Liver, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5160.0,
    price: 2580.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
        "doctor recommended full body checkup advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "doctor recommended full body checkup advanced",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetes (2)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "69",
    productName: "Drug Panel 10 (10 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 10 tests)",
        tests: [
          "Amphetamine",
          "Barbiturates",
          "Benzodiazepines",
          "Cocaine",
          "Methadone",
          "Methamphetamine",
          "Opiates",
          "Phencyclidine",
          "Propoxyphene",
          "Tetrahydrocannabinol",
        ],
      },
    ],
    desc: "Tests Includes Drug of Abuse ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3920.0,
    price: 1960.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Drug panels and urine drug tests are commonly adopted qualitative tests for determining the misuse of prescription medications or abuse of illicit drugs. Drug Panel 10 looks for the presence of 10 common legal and illicit drugs, namely amphetamine, barbiturates, benzodiazepines, cocaine, methadone, methamphetamine, opiates, phencyclidine, propoxyphene and tetrahydrocannabinol, using urine samples. Book the Drug Panel 10 from Thyrocare at an attractive price and get tested from the comfort of your home or walk in at our centre nearest to you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 10",
    overlayDetails: ["Drug of Abuse (10)"],
    avgRating: 4.5,
  },

  {
    id: "70",
    productName: "Drug Panel 2 (2 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 2 tests)",
        tests: ["Cocaine", "Tetrahydrocannabinol"],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 920.0,
    price: 460.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "A drug panel is a common way to check for the presence of various drugs in a person’s body. It is used to check for the legal and illicit drugs some people abuse. Drug panel 2 includes qualitative tests that analyze the most common drugs, namely cocaine and tetrahydrocannabinol, in the sample. Avail your drug panel 2 today at the comfort of your home only at Thyrocare, you can also visit the nearest collection centre.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 2 ",
    overlayDetails: ["Drug of Abuse (2)"],
    avgRating: 4.5,
  },

  {
    id: "71",
    productName: "Drug Panel 3 (3 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 3 tests)",
        tests: ["Cocaine", "Opiates", "Tetrahydrocannabinol"],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1240.0,
    price: 620.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Drug panels and urine drug screens are widely qualitative tests for determining drug abuse.  Thyrocare offers Drug Panel 3, a panel that looks for the presence of 3 of most commonly abused drugs, namely cocaine, opiates and tetrahydrocannabinol using urine samples. Get tested at the best rate with Thyrocare’s Drug Panel 3 and avail home collection facility or visit our centre near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 3",
    overlayDetails: ["Drug of Abuse (3)"],
    avgRating: 4.5,
  },

  {
    id: "72",
    productName: "Drug Panel 4",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 4 tests)",
        tests: ["Amphetamine", "Cocaine", "Opiates", "Tetrahydrocannabinol"],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1540.0,
    price: 770.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Drug panel 4 is a standard qualitative test that entails the collection of a urine specimen to determine if drug metabolites, such as amphetamine, cocaine, opiates and tetrahydrocannabinol, exist in the sample. Book Drug panel 4 with Thyrocare now and opt for home collection or walk-in at our nearest collection centre.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 4",
    overlayDetails: ["Drug of Abuse (4)"],
    avgRating: 4.5,
  },

  {
    id: "73",
    productName: "Drug Panel 5 (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 5 tests)",
        tests: [
          "Amphetamine",
          "Cocaine",
          "Opiates",
          "Phencyclidine",
          "Tetrahydrocannabinol",
        ],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1960.0,
    price: 980.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Drug abuse or addiction is caused by the habitual taking of addictive substances. Drug panel 5 includes qualitative tests that use urine as the sample to test for the presence of commonly abused drugs, such as amphetamine, cocaine, opiates and tetrahydrocannabinol. Get Thyrocare's drug panel 5 at an affordable price with home collection services or walk-in at our center near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 5",
    overlayDetails: ["Drug of Abuse (5)"],
    avgRating: 4.5,
  },

  {
    id: "74",
    productName: "Drug Panel 6 (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 6 tests)",
        tests: [
          "Amphetamine",
          "Benzodiazepines",
          "Cocaine",
          "Methamphetamine",
          "Opiates",
          "Tetrahydrocannabinol",
        ],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2360.0, 
    price: 1180.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s drug panel 6 includes qualitative tests that use a urine sample to evaluate the presence of any drug abuse. This panel covers most popular illicit drugs, namely amphetamine, benzodiazepines, cocaine, opiates, phencyclidine and tetrahydrocannabinol, to evaluate and detect the presence of drug overdose or intoxication. To get this panel at the best price, walk in at our nearest centre or opt for home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 6",
    overlayDetails: ["Drug of Abuse (6)"],
    avgRating: 4.5,
  },

  {
    id: "75",
    productName: "Drug Panel 7 (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 7 tests)",
        tests: [
          "Amphetamine",
          "Barbiturates",
          "Benzodiazepines",
          "Cocaine",
          "Opiates",
          "Phencyclidine",
          "Tetrahydrocannabinol",
        ],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2360.0,
    price: 1180.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s drug panel 6 includes qualitative tests that use a urine sample to evaluate the presence of any drug abuse. This panel covers most popular illicit drugs, namely amphetamine, benzodiazepines, cocaine, opiates, phencyclidine and tetrahydrocannabinol, to evaluate and detect the presence of drug overdose or intoxication. To get this panel at the best price, walk in at our nearest centre or opt for home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 7",
    overlayDetails: ["Drug of Abuse (7)"],
    avgRating: 4.5,
  },

  {
    id: "76",
    productName: "Drug Panel 8 (8 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 8 tests)",
        tests: [
          "Amphetamine",
          "Barbiturates",
          "Benzodiazepines",
          "Cocaine",
          "Methamphetamine",
          "Opiates",
          "Phencyclidine",
          "Tetrahydrocannabinol",
        ],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3100.0,
    price: 1550.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Screening panels and tests using urine samples are widely qualitative tests for detecting improper use of prescription medications or abuse of illicit drugs. Drug Panel 8 screens the presence of 8 legal as well as illicit or street drugs, namely amphetamine, barbiturates, benzodiazepines, cocaine, methamphetamine, opiates, phencyclidine and tetrahydrocannabinol by analyzing a urine sample. Avail Thyrocare’s Drug Panel 8 at an affordable price with a home collection facility or walk-in at our centre near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 8",
    overlayDetails: ["Drug of Abuse (8)"],
    avgRating: 4.5,
  },

  {
    id: "77",
    productName: "Drug Panel 9 (9 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Drug of Abuse (Includes 9 tests)",
        tests: [
          "Amphetamine",
          "Barbiturates",
          "Benzodiazepines",
          "Cocaine",
          "Methadone",
          "Methamphetamine",
          "Opiates",
          "Phencyclidine",
          "Tetrahydrocannabinol",
        ],
      },
    ],
    desc: "Tests Includes Drug of Abuse",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3500.0,
    price: 1750.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Drug screening tests and panels that use urine as a sample are common qualitative tests for determining abuse of prescription medications or use of illicit drugs. Thyrocare’s Drug Panel 9 checks for the presence of 9 legal and illicit drugs, namely amphetamine, barbiturates, benzodiazepines, cocaine, methadone, methamphetamine, opiates, phencyclidine and tetrahydrocannabinol, in the body by analyzing urine samples. Book the Drug Panel 9 from Thyrocare at a discounted price and avail our home collection services or visit the nearest Thyrocare centre.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "drug panel 9",
    overlayDetails: ["Drug of Abuse (9)"],
    avgRating: 4.5,
  },

  {
    id: "78",
    productName: "Elements 22 (Toxic And Nutrients) (22 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
    ],
    desc: "Tests Includes Toxic Elements",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2000.0,
    price: 1000.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "elements 22 (toxic and nutrients)",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "elements 22 (toxic and nutrients) (22)",
    overlayDetails: ["TOXIC ELEMENTS (22)"],
    avgRating: 4.5,
  },

  {
    id: "79",
    productName: "Fat - Soluble Vitamins Profile (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Vitamin (Includes 6 tests)",
        tests: [
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
          "Vitamin a",
          "Vitamin e",
          "Vitamin k",
        ],
      },
    ],
    desc: "Tests Includes Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3700.0,
    price: 1850.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Fat - Soluble Vitamin profile determines how each of the vitamins namely, A, D, E, and K act within the body. These tests included help in understanding levels of fat-soluble vitamins which are micronutrients essential for vitality and good health. High levels of these vitamins could lead to toxicity in the body. This test is available at a discounted rate, along with the home collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "fat - soluble vitamins profile (6)",
    overlayDetails: ["Vitamin (6)"],
    avgRating: 4.5,
  },

  {
    id: "80",
    productName: "Freedom Healthy Package 2024 (111 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Iron Deficiency (Includes 1 tests)",
        tests: ["Iron"],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "Vitamin (Includes 6 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Complete Urine Analysis, Diabetes, Iron Deficiency, Lipid, Liver, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3598.0,
    price: 1799.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Freedom Healthy Package 2024 is a preventive care routine health checkup package that includes 111 parameters with essential blood and urine tests for routine health monitoring. The package is highly recommended for regular health monitoring, even when there are no health issues. One can detect any potential health problems at an early stage and seek right medical treatment at the right time.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "freedom healthy package 2024 (111)",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetes (2)",
      "Iron Deficiency (1)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "81",
    productName: "Ft3-Ft4-Ustsh (3 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1220.0,
    price: 610.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "ft3-ft4-ustsh",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "ft3-ft4-ustsh",
    overlayDetails: ["Thyroid (3)"],
    avgRating: 4.5,
  },

  {
    id: "82",
    productName: "Gastro / Gut Health Panel (42 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 Tests)",
        tests: ["Amylase", "Lipase"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Liver, Pancreatic",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1640.0,
    price: 820.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "A healthy gut contains healthy bacteria, and their imbalance can cause several infections and health problems, including heart diseases and obesity. Gastro/Gut Health Panel is a comprehensive package of 38 tests that gives a detailed information about your gut health. Book Thyrocare Gastro/Gut Health Panel and know your gut health at affordable rates.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "gastro / gut health panel",
    overlayDetails: ["Complete Hemogram (28)", "Liver (12)", "Pancreatic (2)"],
    avgRating: 4.5,
  },

  {
    id: "83",
    productName: "H3n2 Plus Influenza Profile (4 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Infection (Includes 4 tests)",
        tests: [
          "H1n1 influenza a (pdm09)",
          "H3n2",
          "Influenza a",
          "Influenza b",
        ],
      },
    ],
    desc: "Tests Includes Infection",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8760.0,
    price: 4380.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s H3N2 Plus Influenza Profile includes 4 parameters - H3N2, H1N1, Influenza A and Influenza B. Get tested if you experience symptoms like fever, cough, runny nose, sore throat, fatigue, diarrhea and vomiting. Stay safe in this outbreak. Book now at the best rates and get a free home collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "h3n2 plus influenza profile",
    overlayDetails: ["Infection (4)"],
    avgRating: 4.5,
  },

  {
    id: "84",
    productName: "Hemogram - 6 Part (Diff) (28 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
    ],
    desc: "Tests Includes Complete Hemogram",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 600.0,
    price: 300.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "CBC test is a blood test that measures the levels of different blood cells in your body. Testing white blood cells, red blood cells, platelets and hemoglobin helps determine your overall health status and specific health issues. Book test at affordable rates. Lab visits and FREE home sample collection available. Get accurate and timely diagnosis in less than 6 hours for correct treatment.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "hemogram - 6 part (diff)",
    overlayDetails: ["Complete Hemogram (28)"],
    avgRating: 4.5,
  },

  {
    id: "84",
    productName: "Hepatitis Panel (52 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "HEPATITIS PANEL (Includes 1 tests)",
        tests: [
          "Hepatitis b envelope antibody",
          "Anti hepatitis c virus (anti hcv) - total",
          "Hepatitis b envelope antigen (hbeag)",
          "Anti hepatitis a virus (anti hav) - igm",
          "Anti hepatitis a virus (hav) - total",
          "Anti hepatitis b core antigen (ahbc) - igm",
          "Anti hepatitis b core antigen (ahbc) - total",
          "Anti hepatitis b surface antigen - total",
          "Anti hepatitis e virus (anti hev) - igm",
        ],
      },
      {
        categoryName: "Infectious Diseases (Includes 1 tests)",
        tests: ["Hepatitis b surface antigen (hbsag)"],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Hepatitis Panel, Infectious Diseases, Liver",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 10300.0,
    price: 5150.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "It is an array of tests used to detect hepatitis infections. The parameters cover various antigens which assesses the presence of Hepatitis A, B, E and C viruses. This profile is generally recommended to people suffering with abdominal pain, dark colored urine, jaundice, vomiting, weight loss, fatigue and general itching. These tests detect current or past infections, how contagious is the infection, as well as whether it has caused liver damage.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "hepatitis panel",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetes (2)",
      "Hepatitis Panel (9)",
      "Infectious Diseases (1)",
      "Liver (12)",
    ],
    avgRating: 4.5,
  },

  {
    id: "85",
    productName: "Infection Checkup Profile (37 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "High sensitivity c-reactive protein (hs-crp)",
          "Lactate dehydrogenase (ldh)",
        ],
      },
      {
        categoryName: "Coagulation (Includes 1 tests)",
        tests: ["D-dimer"],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Infection (Includes 2 tests)",
        tests: ["Interleukin-6", "Procalcitonin"],
      },
      {
        categoryName: "Iron Deficiency (Includes 1 tests)",
        tests: ["Ferritin"],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Coagulation, Complete Hemogram, Infection, Iron Deficiency, Liver",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6180.0,
    price: 3090.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Infection Checkup Profile is a common test used for the detection of any infection in the body. An infection Checkup profile being a combination of tests helps in identifying the early signs of viral or bacterial infections that require fasting blood samples. This profile is suggested for individuals with infection symptoms like fever, chills or sweats, vomiting, nasal congestion, sore throat, diarrhea, pain, unusual vaginal discharge, increased urination, soreness, or swelling. Book Infection Checkup Profile from Thyrocare at an affordable price and get home collection services.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "infection checkup profile",
    overlayDetails: [
      "Cardiac Risk Markers (2)",
      "Coagulation (1)",
      "Complete Hemogram (28)",
      "Infection (2)",
      "Iron Deficiency (1)",
      "Liver (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "86",
    productName: "Infertility - Extended (30 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Diabetes (Includes 4 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Postprandial blood sugar(glucose)",
        ],
      },
      {
        categoryName: "Hormone (Includes 2 tests)",
        tests: ["Free androgen index", "Testosterone"],
      },
      {
        categoryName: "Hypertension (Includes 1 tests)",
        tests: ["Aldosterone"],
      },
      {
        categoryName: "Infertility (Includes 6 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
          "Sex hormone binding globulin (shbg)",
        ],
      },
      {
        categoryName: "Steroid (Includes 9 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitmains (Includes 5 tests)",
        tests: [
          "Folate",
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
          "Vitamin b-12",
        ],
      },
    ],
    desc: "Tests Includes Diabetes, Hormone, Hypertension, Infertility, Steroid, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 9280.0,
    price: 4640.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "infertility - extended",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "infertility - extended",
    overlayDetails: [
      "Diabetes (4)",
      "Hormone (2)",
      "Hypertension (1)",
      "Infertility (6)",
      "Steroid (9)",
      "Thyroid (3)",
      "Vitamins (5)",
    ],
    avgRating: 4.5,
  },

  {
    id: "87",
    productName: "Infertility Advanced (21 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Hypertension (Includes 1 tests)",
        tests: ["Aldosterone"],
      },
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Steroid (Includes 10 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
          "Testosterone",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitamins (Includes 3 tests)",
        tests: ["Vitamin d total", "Vitamin d2", "Vitamin d3"],
      },
    ],
    desc: "Tests Includes Hypertension, Infertility, Steroid, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8240.0,
    price: 4120.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "infertility advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "infertility advanced ",
    overlayDetails: [
      "Hypertension (1)",
      "Infertility (4)",
      "Steroid (10)",
      "Thyroid (3)",
      "Vitamins (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "88",
    productName: "Infertility Basic (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Infertility (Includes 7 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes Infertility, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4540.0,
    price: 2270.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "infertility basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "infertility basic",
    overlayDetails: ["Infertility (4)", "Thyroid (3)"],
    avgRating: 4.5,
  },

  {
    id: "89",
    productName: "Infertility Basic (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Luteinising hormone (lh)Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes Infertility, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4540.0,
    price: 2270.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "infertility basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "infertility basic",
    overlayDetails: ["Infertility (4)", "Thyroid (3)"],
    avgRating: 4.5,
  },

  {
    id: "90",
    productName: "Infertility Profile - Advanced (21 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Hypertension (Includes 1 tests)",
        tests: ["Aldosterone"],
      },
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Steroid (Includes 10 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
          "Testosterone",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Thyroid stimulating hormone (tsh)",
        ],
      },
      {
        categoryName: "Vitamin (Includes 3 tests)",
        tests: ["Vitamin d total", "Vitamin d2", "Vitamin d3"],
      },
    ],
    desc: "Tests Includes Hypertension, Infertility, Steroid, Thyroid, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8000.0,
    price: 4000.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Infertility Profile - Advanced includes 21 tests that help you check levels of hormones that are required for maintaining your reproductive system. This profile helps you better understand your menstrual health and if there are any potential health conditions that require medical attention. You can also learn about your ability to get pregnant at a specific time frame. Book Infertility Profile - Advanced at an affordable price and get a free home collection or walk-in at our centre near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "infertility profile - advanced",
    overlayDetails: [
      "Hypertension (1)",
      "Infertility (4)",
      "Steroid (10)",
      "Thyroid (3)",
      "Vitamin (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "91",
    productName: "Influenza Panel (Influenza a and b and h1n1) (3 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Infection (Includes 3 tests)",
        tests: ["H1n1 influenza a (pdm09)", "Influenza a", "Influenza b"],
      },
    ],
    desc: "Tests Includes Infection",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6180.0,
    price: 3090.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Influenza or flu, is a contagious respiratory illness caused by the influenza viruses. These can cause mild to severe illness. The Influenza Panel helps the doctor determine if you have the flu and the type of virus causing it. Book Thyrocare’s Influenza Panel at the best rate and get a free home collection or walk-in at our nearest centre.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "influenza panel (influenza a and b and h1n1)",
    overlayDetails: ["Infection (3)"],
    avgRating: 4.5,
  },

  {
    id: "92",
    productName: "Iron Deficiency Profile (4 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
    ],
    desc: "Tests Includes Iron Deficiency",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1360.0,
    price: 680.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "This profile aids to measure the amount of iron present in the body which helps to detect an iron deficiency or overload. The deficiency of iron leads to anemia, shortness of breath, headache, weakness, chest pain, while iron overload can cause organ damage and toxicity in the body. Thyrocare offers Iron Deficiency Profile at the most affordable price. Sample collection at home available.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "iron deficiency profile",
    overlayDetails: ["Iron Deficiency (4)"],
    avgRating: 4.5,
  },

  {
    id: "93",
    productName: "Kidpro (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
    ],
    desc: "Tests Includes Renal",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1700.0,
    price: 850.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "A combination of 7 tests helps in determining the health of kidneys, and how well they are filtering the fluids and excreting the waste. Also, it helps to detect the percentage of Calcium in the body. Low levels of Calcium cause muscle pain and spasm or even abnormal heart rhythms whereas, high levels can lead to the formation of kidney stones. Book Kidpro test to avail the service at your doorsteps at a low cost.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "kidpro",
    overlayDetails: ["Renal (7)"],
    avgRating: 4.5,
  },

  {
    id: "94",
    productName: "Lipid Profile (10 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
    ],
    desc: "Tests Includes Lipid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1100.0,
    price: 550.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "This blood measures the levels of good and bad cholesterol in the blood and helps predict your risk of heart disease and stroke. Book this profile now at affordable costs & get a free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "lipid profile",
    overlayDetails: ["Lipid (10)"],
    avgRating: 4.5,
  },

  {
    id: "95",
    productName: "Liver Function Tests (12 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
    ],
    desc: "Tests Includes Liver",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1700.0,
    price: 850.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "An unhealthy liver often results in fatigue or weakness and loss of appetite. As the name suggests, the Liver Function Test contains 12 parameters that help in assessing the proper functionality of the liver and detect the presence of any damage or associated diseases. Early detection of abnormality in the liver functions may eliminate further damage to the liver and help avail effective treatment. Book liver function tests from Thyrocare at an affordable price and get home collection services.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "liver function tests",
    overlayDetails: ["Liver (12)"],
    avgRating: 4.5,
  },

  {
    id: "96",
    productName: "Malarial Antigen (2 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Fever (Includes 2 tests)",
        tests: ["Plasmodium falciparum", "Plasmodium vivax"],
      },
    ],
    desc: "Tests Includes Fever",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1160.0,
    price: 580.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "malarial antigen",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "malarial antigen",
    overlayDetails: ["Fever (2)"],
    avgRating: 4.5,
  },

  {
    id: "97",
    productName: "Male Cancer Screening Profile (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cancer Markers (Includes 6 tests)",
        tests: [
          "Alpha feto protein (afp)",
          "Beta hcg",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
          "Free psa",
          "Prostate specific antigen (psa)",
        ],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["Percent free psa"],
      },
    ],
    desc: "Tests Includes Cancer Markers, Cardiac Risk Markers",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6180.0,
    price: 3090.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "This comprehensive profile aids in screening cancers of various organs, such as the liver, pancreas, lungs, prostate gland, testes, and intestine in men. The package comprises 6 parameters including tests for evaluating proteins like Alpha-Fetoprotein, Cancer antigen 19.9, free and total prostate-specific antigen, Carcinoembryonic antigen, and beta-human chorionic gonadotropin hormone. Get Thyrocare's Male Cancer Screening Profile at an affordable price with free home collection services or walk-in at our center near you.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "male cancer screening profile",
    overlayDetails: ["Cancer Markers (6)", "Cardiac Risk Markers (1)"],
    avgRating: 4.5,
  },

  {
    id: "98",
    productName: "Male Hormone Screening Panel (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Hormone (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "Infertility (Includes 3 tests)",
        tests: [
          "Androstenedione (a4)",
          "Free testosterone",
          "Sex hormone binding globulin (shbg)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Human growth hormone (hgh)"],
      },
      {
        categoryName: "Steroid Hormone (Includes 1 tests)",
        tests: ["Dihydrotestosterone (dht)"],
      },
    ],
    desc: "Tests Includes Hormone, Infertility, Metabolic, Steroid Hormone",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 7220.0,
    price: 3610.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "Male Hormone Screening Panel contains 6 tests that evaluate the levels of hormones that are important in maintaining sperm production, sexual functions and sex drive. The test is often recommended when a couple is having trouble conceiving a baby. A hormonal imbalance affects your overall body growth, sexual characteristics and more. Book now at affordable prices and get a free home collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "male hormone screening panel",
    overlayDetails: [
      "Hormone (1)",
      "Infertility (3)",
      "Metabolic (1)",
      "Steroid Hormone (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "99",
    productName: "Mens Hairfall Screening - Advanced (47 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 2 tests)",
        tests: ["Anti nuclear antibodies (ana)", "Phosphorous"],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Elements (Includes 1 tests)",
        tests: ["Serum zinc"],
      },
      {
        categoryName: "Infertility (Includes 1 tests)",
        tests: ["Sex hormone binding globulin (shbg)"],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 2 tests)",
        tests: ["Cortisol", "Magnesium"],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Calcium"],
      },
      {
        categoryName: "Steroid Hormone (Includes 1 tests)",
        tests: ["Dihydrotestosterone (dht)"],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Thyroid stimulating hormone (tsh)",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Elements, Infertility, Iron Deficiency, Metabolic, Renal, Steroid Hormone, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5498.0,
    price: 2749.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    " Book Men's Hairfall Screening : Too much hair fall and don’t know why? It could be genetic or due to lifestyle factors. Get to the root cause today with Thyrocare’s new MEN’S HAIR FALL SCREENING - ADVANCE profile. It covers 43 tests including thyroid testing, complete blood count, iron deficiency profile, inflammatory markers, vitamins, minerals and essential elements only at Rs 2749/- Book your test now at a reasonable cost and get a free home collection. ",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "mens hairfall screening - advanced",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)",
      "Elements (1)",
      "Infertility (1)",
      "Iron Deficiency (5)",
      "Metabolic (2)",
      "Renal (1)",
      "Steroid Hormone (1)",
      "Thyroid (3)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "100",
    productName: "Monsoon Fever Panel - Advanced (40 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Fever (Includes 4 tests)",
        tests: [
          "Plasmodium falciparum",
          "Plasmodium vivax",
          "Typhoid-igg",
          "Typhoid-igm",
        ],
      },
      {
        categoryName: "Infection (Includes 2 tests)",
        tests: ["Dengue-igg - elisa", "Dengue-igm - elisa"],
      },
      {
        categoryName: "Infectious Disease (Includes 1 tests)",
        tests: ["Dengue ns1 antigen - elisa"],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Other Counts (Includes 1 tests)",
        tests: ["Erythrocyte sedimentation rate (esr)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Fever, Infection, Infectious Diseases, Liver, Other Counts",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4000.0,
    price: 2000.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "Monsoon brings hosts of infections. Proper precautions and early diagnosis can help you enjoy the monsoon season with good health. Prepare yourself to fight health problems this rainy season with our Monsoon Fever Panel Advanced, comprising 36 parameters. This includes a complete hemogram, cardiac risk markers and parameters for fever and infectious diseases like dengue, malaria, typhoid and chikungunya at extremely competitive prices. Book this profile and get tested from the comfort of your home with a FREE home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "monsoon fever panel - advanced",
    overlayDetails: [
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)",
      "Fever (4)",
      "Infection (2)",
      "Infectious Diseases (1)",
      "Liver (3)",
      "Other Counts (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "101",
    productName: "Monsoon Fever Panel - Basic (37 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Fever (Includes 4 tests)",
        tests: [
          "Plasmodium falciparum",
          "Plasmodium vivax",
          "Typhoid-igg",
          "Typhoid-igm",
        ],
      },
      {
        categoryName: "Infectious Disease (Includes 1 tests)",
        tests: ["Dengue ns1 antigen - elisa"],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Other Counts (Includes 1 tests)",
        tests: ["Erythrocyte sedimentation rate (esr)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Fever, Infectious Diseases, Liver, Other Counts",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3000.0,
    price: 1500.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "monsoon fever panel - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "monsoon fever panel - basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Fever (4)",
      "Infectious Disease (1)",
      "Liver (3)",
      "Other Counts (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "102",
    productName: "Newborn Screening - 4 (4 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "New Born Screening (Includes 4 tests)",
        tests: [
          "Neonatal g6pd",
          "Neonatal 17 alpha-hydroxyprogesterone",
          "Neonatal total galactose",
          "Thyroid stimulating hormone",
        ],
      },
    ],
    desc: "Tests Includes New Born Screening",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3100.0,
    price: 1550.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "newborn screening - 4",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "newborn screening - 4",
    overlayDetails: ["New Born Screening (4)"],
    avgRating: 4.5,
  },

  {
    id: "103",
    productName: "Newborn Screening Profile (43 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Amino Acid Porfile (Includes 10 tests)",
        tests: [
          "Alanine",
          "Arginine",
          "Citrulline",
          "Glycine",
          "Leucine",
          "Methionine",
          "Ornithine",
          "Phenylalanine",
          "Tyrosine",
          "Valine",
        ],
      },
      {
        categoryName: "Biochemical Panel Profile (Includes 7 tests)",
        tests: [
          "Neonatal biotinidase",
          "Neonatal g6pd",
          "Neonatal immunoreactive trypsin",
          "Neonatal 17 alpha-hydroxyprogesterone",
          "Neonatal phenylketonuria",
          "Neonatal total galactose",
          "Thyroid stimulating hormone",
        ],
      },
      {
        categoryName: "Fatty Acid Profile (Includes 19 tests)",
        tests: [
          "3-hydroxybutyryl-carnitine (c4oh)",
          "3-hydroxyoctadecanoyl-carnitine (c18-0h)",
          "Decanoyl-carnitine (c10)",
          "Decenoyl-carnitine (c10:1)",
          "Dodecanoyl-carnitine (c12)",
          "Free carnitine (c0)",
          "Glutaryl-carnitine (c5dc)",
          "Hexanoyl-carnitine (c6)",
          "3-hydroxyhexadecanoyl-carnitine (c16oh)",
          "3-hydroxyhexadecenoyl-carnitine (c16:1-oh)",
          "3-hydroxyoctadecenoyl-carnitine (c18:1oh)",
          "Hexadecanoyl-(palmitoyl)-carnitine (c16)",
          "Isobutyryl- butyryl-carnitine (c4)",
          "Octadecadienoyl-carnitine (c18:2)",
          "Octanoyl-carnitine (c8)",
          "Octadecenoyl-carnitine (c18:1)",
          "Octadecanoyl-(stearyl)-carnitine (c18)",
          "Tetradecenoyl-carnitine (c14:1)",
          "Tetradecanoyl-(myristoyl)-carnitine (c14)",
        ],
      },
      {
        categoryName: "New Born Screening (Includes 1 tests)",
        tests: ["Argininosuccinic acid"],
      },
      {
        categoryName: "Organic Acid Profile (Includes 6 tests)",
        tests: [
          "3-oh isovaleryl-2methyl-3-oh butyryl-carnitine (c5oh)",
          "Acetyl carnitine (c2)",
          "Isovaleryl-/2-methylbutyryl-carnitine (c5)",
          "Methylglutaryl carnitine (c6dc)",
          "Propionyl carnitine (c3)",
          "Tiglyl-carnitine (c5:1)",
        ],
      },
    ],
    desc: "Tests Includes Amino Acid Profile, Biochemical Panel Profile, Fatty Acid Profile, New Born Screening, Organic Acid Profile",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8000.0,
    price: 4000.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "newborn screening profile",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "newborn screening profile",
    overlayDetails: [
      "Amino Acid Porfile (10)",
      "Biochemical Panel Profile (7)",
      "Fatty Acid Profile (19)",
      "New Born Screening (1)",
      "Organic Acid Profile (6)",
    ],
    avgRating: 4.5,
  },

  {
    id: "104",
    productName: "Paleo Profile - Advanced (107 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 6 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fasting blood sugar(glucose)",
          "Fructosamine",
          "Hba1c",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "Pancreatic (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "Renal (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Thyroid stimulating hormone (tsh)"],
      },
      {
        categoryName: "Vitamin (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Complete Urine Analysis, Diabetes, Electrolytes, Iron Deficiency, Lipid, Liver, Metabolic, Pancreatic, Renal, Thyroid, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 8198.0,
    price: 4099.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "paleo profile - advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "paleo profile - advanced",
    overlayDetails: [
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetes (6)",
      "Electrolytes (2)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Metabolic (1)",
      "Pancreatic (2)",
      "Renal (9)",
      "Thyroid (1)",
      "Vitamin (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "105",
    productName: "Paleo Profile - Basic (95 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 4 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Lipid (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "Renal (Includes 9 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Thyroid stimulating hormone (tsh)"],
      },
      {
        categoryName: "Vitamin (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Complete Urine Analysis, Diabetes, Iron Deficiency, Lipid, Liver, Metabolic, Renal, Thyroid, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5398.0,
    price: 2699.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "paleo profile - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "paleo profile - basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "COMPLETE URINE ANALYSIS (24)",
      "Diabetes (4)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Metabolic (1)",
      "Renal (9)",
      "Thyroid (1)",
      "Vitamin (2)",
    ],
    avgRating: 4.5,
  },
  {
    id: "106",
    productName: "Pcod Profile (37 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 3 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c", "Insulin - fasting"],
      },
      {
        categoryName: "Hormone (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Thyroid stimulating hormone (tsh)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Hormone, Infertility, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6180.0,
    price: 3090.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Thyrocare’s PCOD profile includes 33 tests for measuring the levels of hormones in your body. Abnormal hormonal levels lead to irregular menstrual cycle, multiple cysts formation, infertility, hair and skin problems, diabetes, and much more. Book a PCOD profile to better understand your reproductive health. Get a free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "pcod profile",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetes (3)",
      "Hormone (1)",
      "Infertility (4)",
      "Thyroid (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "107",
    productName: "Pcos Advanced (73 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "Hormone (Includes 2 tests)",
        tests: ["Free androgen index", "Testosterone"],
      },
    ],
    desc: "Tests Includes Lipid, Complete Hemogram, Toxic Elements, Thyroid, Diabetes, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Elements, Hormone ",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5998.0,
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "pcos advanced",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetes (6)",
      "Hormone (2)",
      "Infertility (8)",
      "Lipid (10)",
      "Liver (12)",
      "Pregnancy (1)",
      "Renal (4)",
      "Steroid Hormone (1)",
      "Thyroid (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "108",
    productName: "Pcos Basic (69 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "Hormone (Includes 3 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "Infertility (Includes 6 tests)",
        tests: [
          "17 oh progesterone",
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 3 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "Renal (Includes 4 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Creatinine - serum",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Hormone, Infertility, Lipid, Liver, Pregnanacy, Renal, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 7198.0,
    price: 3599.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "pcos basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "pcos basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetes (6)",
      "Hormone (1)",
      "Infertility (6)",
      "Lipid (10)",
      "Liver (12)",
      "Pregnancy (1)",
      "Renal (4)",
      "Thyroid (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "109",
    productName: "Pcos Mini (51 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Diabetes (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "Infertility (Includes 5 tests)",
        tests: [
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Infertility, Lipid, Pregnancy, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5198.0,
    price: 2599.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "pcos mini",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "pcos mini",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetes (6)",
      "Infertility (5)",
      "Lipid (10)",
      "Pregnancy (1)",
      "Thyroid (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "110",
    productName: "Post Covid Care (39 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 1 tests)",
        tests: ["Random blood sugar(glucose)"],
      },
      {
        categoryName: "Infectious Diseases (Includes 1 tests)",
        tests: ["Covid antibody igg - elisa"],
      },
      {
        categoryName: "Liver (Includes 4 tests)",
        tests: [
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Creatinine - serum"],
      },
      {
        categoryName: "Thyrpid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Thyroid stimulating hormone (tsh)",
        ],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Infectious Diseases, Liver, Renal, Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2460.0,
    price: 1230.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "Thyrocare offers Post COVID Care, a profile that consists of 35 tests and is ideal for individuals who have recovered from COVID-19 infection. It includes routine check-up tests like Complete hemogram, Thyroid profile, Erythrocyte sedimentation rate as well as tests like Aspartate aminotransferase, Alanine transaminase, Gamma-glutamyl transferase, Antibody IgG, Creatinine - serum, and Random blood sugar. SARS-CoV-2, the causative agent of COVID-19 with its variants has become a global health challenge. Because there is so little knowledge about the long-term implications of COVID infection, it is critical to keep track of patients&#39; progress. People who have had a severe SARS-CoV-2 infection develop multiorgan effects, which disrupt the functioning of some important organs such as the heart, brain, lungs, kidneys, and others. Regular monitoring ensures that patients are diagnosed accurately in a timely manner and get prompt medical attention. Book a Post COVID Care Profile at Thyrocare for reliable and affordable health management.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "post covid care",
    overlayDetails: [
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)",
      "Diabetes (1)",
      "Infectious Disease (1)",
      "Liver (4)",
      "Renal (1)",
      "Thyroid (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "111",
    productName: "Postpartum Health Checkup - Advanced (60 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: [
          "Fasting blood sugar(glucose)",
          "Postprandial blood sugar(glucose)",
        ],
      },
      {
        categoryName: "Infertility (Includes 3 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: ["Protein - total", "Albumin - serum", "Serum globulin"],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Adrenocorticotropic hormone (acth)"],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Cortisol"],
      },
      {
        categoryName: "Pregnancy (Includes 2 tests)",
        tests: ["Estradiol/oestrogen (e2)", "Progesterone"],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Calcium"],
      },
      {
        categoryName: "Thyroid (Includes 2 tests)",
        tests: ["Free thyroxine (ft4)", "Tsh - ultrasensitive"],
      },
      {
        categoryName: "Vitamins (Includes 3 tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Infertility, Iron Deficiency, Lipid, Liver, Metabolic, Pregnancy, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5160.0,
    price: 2580.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "postpartum health checkup - advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "postpartum health checkup - advanced",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetes (2)",
      "Infertility (3)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (3)",
      "Metabolic (1)",
      "Metabolic (1)",
      "Pregnancy (2)",
      "Renal (1)",
      "Thyroid (2)",
      "Vitamins (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "112",
    productName: "Postpartum Health Checkup - Basic (51 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: [
          "Fasting blood sugar(glucose)",
          "Postprandial blood sugar(glucose)",
        ],
      },
      {
        categoryName: "Infertility (Includes 3 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: ["Protein - total", "Albumin - serum", "Serum globulin"],
      },
      {
        categoryName: "Thyroid (Includes 2 tests)",
        tests: ["Free thyroxine (ft4)", "Tsh - ultrasensitive"],
      },
      {
        categoryName: "Vitamins (Includes 3 tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Infertility, Lipid, Liver, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3100.0,
    price: 1550.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "postpartum health checkup - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "postpartum health checkup - basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetes (2)",
      "infertility (3)",
      "Lipid (10)",
      "Liver (3)",
      "Thyroid (2)",
      "Vitamins (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "113",
    productName: "Preeclampsia Profile Advanced (59 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 3 tests)",
        tests: [
          "Placental growth factor",
          "Soluble fms-like tyrosine kinase 1",
          "Sflt-1 and plgf ratio",
        ],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Creatinine - serum"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Complete Urine Analysis, Liver, Pregnancy, Renal",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6800.0,
    price: 3400.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "preeclampsia profile advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "preeclampsia profile advanced",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Liver (3)",
      "Pregnancy (3)",
      "Renal (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "114",
    productName: "Preeclampsia Profile Basic (35 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Hemogram (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 3 tests)",
        tests: [
          "Placental growth factor",
          "Soluble fms-like tyrosine kinase 1",
          "Sflt-1 and plgf ratio",
        ],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Creatinine - serum"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Liver, Pregnancy, Renal",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6180.0,
    price: 3090.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "preeclampsia profile basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "preeclampsia profile basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Liver (3)",
      "Pregnancy (3)",
      "Renal (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "115",
    productName: "Quantiferon -Tb Gold Plus (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Tuberculosis (Includes 5 tests)",
        tests: [
          "Tb1 antigen tube minus nil tube",
          "Tb2 antigen tube minus nil tube",
          "Mitogen tube minus nil tube",
          "Nil tube",
          "Final result",
        ],
      },
    ],
    desc: "Tests Includes Tuberculosis",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5760.0,
    price: 2880.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "quantiferon -tb gold plus",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "quantiferon -tb gold plus",
    overlayDetails: ["Tuberculosis (5)"],
    avgRating: 4.5,
  },

  {
    id: "116",
    productName: "Routine Urine Analysis (14 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Complete Urine Analysis (Includes 4 tests)",
        tests: ["Appearance", "Colour", "Leucocyte esterase", "Volume"],
      },
      {
        categoryName: "Urinogram (Includes 10 tests)",
        tests: [
          "Specific gravity",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Urinary glucose",
          "Urine ketone",
          "Urinary leucocytes (pus cells)",
          "Nitrite",
          "Ph",
          "Urinary protein",
        ],
      },
    ],
    desc: "Tests Includes Complete Urine Analysis, Urinogram",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 300.0,
    price: 150.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "routine urine analysis",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "routine urine analysis",
    overlayDetails: ["Complete Urine Analysis (4)", "Urinogram (10)"],
    avgRating: 4.5,
  },

  {
    id: "117",
    productName: "Serum Electrolytes (3 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Electrolytes (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
    ],
    desc: "Tests Includes Electrolytes",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 920.0,
    price: 460.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "serum electrolytes",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "serum electrolytes",
    overlayDetails: ["Electrolytes (3)"],
    avgRating: 4.5,
  },

  {
    id: "118",
    productName: "Smokers Panel - Advanced (48 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (28)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 3 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
        ],
      },
      {
        categoryName: "Drugs (Includes 1 tests)",
        tests: ["Nicotine metabolites"],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "Vitamins (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Diabetes, Drugs, Lipid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6180.0,
    price: 3090.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Smoking causes many life-threatening conditions, including cancer, diabetes and lung and heart diseases. Smoker’s Panel - Advanced, a comprehensive panel of 42 parameters helps you look for early warning signs and smoking-related conditions. It also helps track your overall health. Book Smoker’s Panel - Advanced now and get a free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "smokers panel - advanced",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetes (3)",
      "Drugs (1)",
      "Lipid (10)",
      "Vitamins (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "119",
    productName: "Smokers Panel - Basic (8 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Drugs (Includes 1 tests)",
        tests: ["Nicotine metabolites"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Diabetes, Drugs",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4120.0,
    price: 2060.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Smoking harms almost every organ in the body and increases your risk of several health problems, such as cancer, diabetes and lung and heart diseases. Smoker’s Panel - Basic, an 8-parameter package helps detect heart and respiratory damage and health complications caused by smoking. Book Smoker’s Panel - Basic and avail free sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "smokers panel - basic",
    overlayDetails: ["Complete Risk Markers (5)", "Diabetes (2)", "Drugs (1)"],
    avgRating: 4.5,
  },

  {
    id: "120",
    productName: "Sports Fitness - Advanced (135 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 1 tests)",
        tests: ["Phosphorous"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 Tests)",
        tests: [
          "APOLIPOPROTEIN - B (APO-B)",
          "APO B / APO A1 RATIO(APO B/A1)",
          "LIPOPROTEIN (a) [Lp(a)]",
          "HIGH SENSITIVITY C-REACTIVE PROTEIN (HS-CRP)",
          "APOLIPOPROTEIN - A1 (APO-A1)",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "NEUTROPHILS-ABSOLUTE COUNT",
          "RED CELL DISTRIBUTION-WIDTH - SD(RDW-SD)",
          "MONOCYTES-ABSOLUTE COUNT",
          "TOTAL RBC",
          "MEAN CORP.HEMO.CONC(MCHC)",
          "BASOPHILS",
          "HEMATOCRIT(PCV)",
          "PLATELET COUNT",
          "EOSINOPHILS",
          "RED CELL DISTRIBUTION WIDTH (RDW-CV)",
          "MEAN CORPUSCULAR HEMOGLOBIN(MCH)",
          "IMMATURE GRANULOCYTE PERCENTAGE(IG%)",
          "LYMPHOCYTE",
          "MEAN CORPUSCULAR VOLUME(MCV)",
          "PLATELET DISTRIBUTION WIDTH(PDW)",
          "NUCLEATED RED BLOOD CELLS",
          "PLATELET TO LARGE CELL RATIO(PLCR)",
          "HEMOGLOBIN",
          "MONOCYTES",
          "BASOPHILS-ABSOLUTE COUNT",
          "IMMATURE GRANULOCYTES(IG)",
          "LYMPHOCYTES-ABSOLUTE COUNT",
          "NUCLEATED RED BLOOD CELLS %",
          "PLATELETCRIT(PCT)",
          "MEAN PLATELET VOLUME(MPV)",
          "TOTAL LEUCOCYTES COUNT (WBC)",
          "NEUTROPHILS",
          "EOSINOPHILS-ABSOLUTE COUNT",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Elements (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "Hematology (Includes 1 tests)",
        tests: ["Troponin i heart attack risk"],
      },
      {
        categoryName: "Infertility (Includes 1 tests)",
        tests: ["Free testosterone"],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (22)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "Vitamins (Includes 9 tests)",
        tests: [
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/pyridoxal - 5 - phosphate",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
          "25-oh vitamin d (total)",
        ],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Complete Urine Analysis, Diabetes, Electrolytes, Elements, Hematology, Infertility, Iron Deficiency, Lipid, Liver, Metabolic, Renal, Thyroid, Toxic Elements, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 7000.0,
    price: 3500.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "sports fitness - advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "sports fitness - advanced",
    overlayDetails: [
      "Arthritis (1)",
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetes (2)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hematology (1)",
      "Infertility (1)",
      "Iron Deficiency (5)",
      "Lipid (10)",
      "Liver (12)",
      "Metabolic (1)",
      "Renal (7)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Vitamins (9)",
    ],
    avgRating: 4.5,
  },

  {
    id: "121",
    productName: "Sports Fitness - Basic (92 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "NEUTROPHILS-ABSOLUTE COUNT",
          "RED CELL DISTRIBUTION-WIDTH - SD(RDW-SD)",
          "MONOCYTES-ABSOLUTE COUNT",
          "TOTAL RBC",
          "MEAN CORP.HEMO.CONC(MCHC)",
          "BASOPHILS",
          "HEMATOCRIT(PCV)",
          "PLATELET COUNT",
          "EOSINOPHILS",
          "RED CELL DISTRIBUTION WIDTH (RDW-CV)",
          "MEAN CORPUSCULAR HEMOGLOBIN(MCH)",
          "IMMATURE GRANULOCYTE PERCENTAGE(IG%)",
          "LYMPHOCYTE",
          "MEAN CORPUSCULAR VOLUME(MCV)",
          "PLATELET DISTRIBUTION WIDTH(PDW)",
          "NUCLEATED RED BLOOD CELLS",
          "PLATELET TO LARGE CELL RATIO(PLCR)",
          "HEMOGLOBIN",
          "MONOCYTES",
          "BASOPHILS-ABSOLUTE COUNT",
          "IMMATURE GRANULOCYTES(IG)",
          "LYMPHOCYTES-ABSOLUTE COUNT",
          "NUCLEATED RED BLOOD CELLS %",
          "PLATELETCRIT(PCT)",
          "MEAN PLATELET VOLUME(MPV)",
          "TOTAL LEUCOCYTES COUNT (WBC)",
          "NEUTROPHILS",
          "EOSINOPHILS-ABSOLUTE COUNT",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Iron Deficiency (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Complete Urine Analysis, Diabetes, Electrolytes, Iron Deficiency, Lipid, Liver, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3300.0,
    price: 1650.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "sports fitness - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "sports fitness - basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetes (2)",
      "Electrolytes (2)",
      "Iron Deficiency (4)",
      "Lipid (10)",
      "Liver (12)",
      "Renal (7)",
      "Thyroid (1)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "122",
    productName: "Sports Fitness - Comprehensive (143 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 1 tests)",
        tests: ["Phosphorous"],
      },
      {
        categoryName: "Cardiac (Includes 3 tests)",
        tests: [
          "Creatinine phosphokinase",
          "Creatinine phosphokinase (muscle/brain)",
          "Myoglobin",
        ],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "NEUTROPHILS-ABSOLUTE COUNT",
          "RED CELL DISTRIBUTION-WIDTH - SD(RDW-SD)",
          "MONOCYTES-ABSOLUTE COUNT",
          "TOTAL RBC",
          "MEAN CORP.HEMO.CONC(MCHC)",
          "BASOPHILS",
          "HEMATOCRIT(PCV)",
          "PLATELET COUNT",
          "EOSINOPHILS",
          "RED CELL DISTRIBUTION WIDTH (RDW-CV)",
          "MEAN CORPUSCULAR HEMOGLOBIN(MCH)",
          "IMMATURE GRANULOCYTE PERCENTAGE(IG%)",
          "LYMPHOCYTE",
          "MEAN CORPUSCULAR VOLUME(MCV)",
          "PLATELET DISTRIBUTION WIDTH(PDW)",
          "NUCLEATED RED BLOOD CELLS",
          "PLATELET TO LARGE CELL RATIO(PLCR)",
          "HEMOGLOBIN",
          "MONOCYTES",
          "BASOPHILS-ABSOLUTE COUNT",
          "IMMATURE GRANULOCYTES(IG)",
          "LYMPHOCYTES-ABSOLUTE COUNT",
          "NUCLEATED RED BLOOD CELLS %",
          "PLATELETCRIT(PCT)",
          "MEAN PLATELET VOLUME(MPV)",
          "TOTAL LEUCOCYTES COUNT (WBC)",
          "NEUTROPHILS",
          "EOSINOPHILS-ABSOLUTE COUNT",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Diabetes (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "Electrolytes (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "Elements (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "Hematology (Includes 1 tests)",
        tests: ["Troponin i heart attack risk"],
      },
      {
        categoryName: "Hypertension (Includes 1 tests)",
        tests: ["Aldosterone"],
      },
      {
        categoryName: "Infertility (Includes 2 tests)",
        tests: ["Free testosterone", "Sex hormone binding globulin (shbg)"],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "STEROID (Includes 9 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
          "Testosterone",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: [
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 Tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "Tuberculosis (Includes 1 tests)",
        tests: ["Active vitamin b12 (holotranscobalamin)"],
      },
      {
        categoryName: "Vitamins (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac, Cardiac Risk Markers, Complete Hemogram, Complete Urine Analysis, Diabetes, Electrolytes, Elements, Hematology, Hypertension, Infertility, Iron Deficiency, Lipid, Liver, Metabolic, Renal, Steroid, Thyroid, Toxic Elements, Tuberculosis, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 13380.0,
    price: 6690.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "sports fitness - comprehensive",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "sports fitness - comprehensive",
    overlayDetails: [
      "Arthritis (1)",
      "Cardiac (3)",
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Complete Urine Analysis (24)",
      "Diabetes (2)",
      "Electrolytes (2)",
      "Elements (2)",
      "Hematology (1)",
      "Hypertension (1)",
      "Infertility (2)",
      "Iron Deficiency (5)",
      "Lipid (10)",
      "Liver (12)",
      "Metabolic (1)",
      "Renal (7)",
      "Steroid (10)",
      "Thyroid (3)",
      "Toxic Elements (22)",
      "Tuberculosis (1)",
      "Vitamins (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "123",
    productName: "Std Profile - Basic (12 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Hepatitis Panel (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },
      {
        categoryName: "INFECTIOUS DISEASES (Includes 11 tests)",
        tests: [
          "Anti chlamydia antibody igg",
          "Anti chlamydia antibody igm",
          "Hiv i and ii",
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus i (hsv)-igm",
          "Herpes simplex virus ii (hsv)-igg",
          "Herpes simplex virus ii (hsv)-igm",
          "Hepatitis b surface antigen (hbsag)",
          "Treponema pallidum antibody (tpab)",
          "Treponema pallidum haemagglutination",
          "Vdrl (rpr) for syphilis",
        ],
      },
    ],
    desc: "Tests Includes Hepatitis Panel, Infectious Diseases",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5160.0,
    price: 2580.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "std profile - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "std profile - basic",
    overlayDetails: ["Hepatitis Panel (1)", "INFECTIOUS DISEASES (11)"],
    avgRating: 4.5,
  },

  {
    id: "124",
    productName: "Std Profile Advanced (12 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Hepatitis Panel (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },
      {
        categoryName: "INFECTIOUS DISEASES (Includes 9 tests)",
        tests: [
          "Hiv i and ii",
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus i (hsv)-igm",
          "Herpes simplex virus ii (hsv)-igg",
          "Herpes simplex virus ii (hsv)-igm",
          "Hepatitis b surface antigen (hbsag)",
          "Treponema pallidum antibody (tpab)",
          "Treponema pallidum haemagglutination",
          "Vdrl (rpr) for syphilis",
        ],
      },
      {
        categoryName: "Std (Includes 2 tests)",
        tests: ["Chlamydia trachomatis pcr", "Neisseria gonorrhoeae pcr"],
      },
    ],
    desc: "Tests Includes Hepatitis Panel, INFECTIOUS DISEASES, Std",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 11940.0,
    price: 5970.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "std profile advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "std profile advanced",
    overlayDetails: [
      "Hepatitis Panel (1)",
      "INFECTIOUS DISEASES (9)",
      "Std (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "125",
    productName: "Std Profile Extended (14 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Hepatitis Panel (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },
      {
        categoryName: "INFECTIOUS DISEASES (Includes 9 tests)",
        tests: [
          "Hiv i and ii",
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus i (hsv)-igm",
          "Herpes simplex virus ii (hsv)-igg",
          "Herpes simplex virus ii (hsv)-igm",
          "Hepatitis b surface antigen (hbsag)",
          "Treponema pallidum antibody (tpab)",
          "Treponema pallidum haemagglutination",
          "Vdrl (rpr) for syphilis",
        ],
      },
      {
        categoryName: "Std (Includes 4 tests)",
        tests: [
          "Chlamydia trachomatis pcr",
          "Mycoplasma genitalium pcr",
          "Neisseria gonorrhoeae pcr",
          "Trichomonas vaginalis pcr",
        ],
      },
    ],
    desc: "Tests Includes Hepatitis Panel, INFECTIOUS DISEASES, Std",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 17520.0,
    price: 8760.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "std profile extended",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "std profile extended",
    overlayDetails: [
      "Hepatitis Panel (1)",
      "INFECTIOUS DISEASES (9)",
      "Std (4)",
    ],
    avgRating: 4.5,
  },

  {
    id: "126",
    productName: "Steroid Profile (14 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Hypertension (Includes 1 tests)",
        tests: ["Aldosterone"],
      },
      {
        categoryName: "STEROID (Includes 10 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
          "Testosterone",
        ],
      },
      {
        categoryName: "Vitamin (Includes 3 tests)",
        tests: ["Vitamin d total", "Vitamin d2", "Vitamin d3"],
      },
    ],
    desc: "Tests Includes Hypertension, STEROID, Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 6180.0,
    price: 3090.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "A complete diagnostic profile to estimate levels of Testosterone and Progesterone in men and women. This in turn helps in assessing a variety of conditions like hypogonadism in men, genetic diseases, chances of miscarriages, ectopic pregnancy along with fertility problems in both genders. Overall test results of the profile are primarily used to detect hormonal causes associated with infertility.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "steroid profile",
    overlayDetails: ["Hypertension (1)", "STEROID (10)", "Vitamin (3)"],
    avgRating: 4.5,
  },

  {
    id: "127",
    productName: "T3-T4-Ustsh (3 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes Thyroid",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 720.0,
    price: 360.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "This is a series of tests used to measure how well your thyroid gland is functioning. The test helps diagnose thyroid dysfunction and the cause of it. Changes in TSH act as early-warning signs and you can prevent any potential diseases. Book the panel now and get a free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "t3-t4-ustsh",
    overlayDetails: ["Thyroid (3)"],
    avgRating: 4.5,
  },

  {
    id: "128",
    productName: "Tb - Whole Genome Sequencing (18 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Others (Includes 18 tests)",
        tests: [
          "Amikacin",
          "Bedaquiline",
          "Capreomycin",
          "Clofazimine",
          "Delamanid",
          "Ethambutol",
          "Ethionamide",
          "Gatifloxacin",
          "Isoniazid",
          "Kanamycin",
          "Linezolid",
          "Antibiogram - mtb (moxifloxacin higher dose)",
          "Ofloxcin",
          "Pas (p-aminosalicylic acid)",
          "Pretomanid",
          "Antibiogram - mtb (pyrazinamide)",
          "Rifampcin",
          "Streptomycin",
        ],
      },
    ],
    desc: "Tests Includes Others",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 16480.0,
    price: 8240.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Tuberculosis or TB, caused by Mycobacterium tuberculosis is one of the diseases with a high fatality rate. The disease spreads when an infected person spits, coughs or sneezes, dispersing the bacteria in the air. Due to the slow growth pattern and multi-drug resistance of Mycobacterium tuberculosis, the disease presents a major challenge in terms of treatment and quick diagnostic techniques. Tuberculosis- Whole Genome Sequencing is a high sensitivity technique that covers 18 antibiotic resistance profiles with a TAT of 12 days. It aids in detecting co-infection and mixed infection as well as helps in strain identification. It helps in the early detection of infection for better individualized treatment with correct antibiotics at the right time. The TB-WGS test is suggested to individuals with symptoms like - cough for more than three weeks, chills, fever, night sweats, loss of appetite, weakness, weight loss.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "tb - whole genome sequencing",
    overlayDetails: ["Others (18)"],
    avgRating: 4.5,
  },

  {
    id: "129",
    productName: "Torch All Eight (8 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "INFECTIOUS DISEASES (Includes 6 tests)",
        tests: [
          "Cytomegalo virus (cmv) - igg",
          "Cytomegalo virus (cmv) - igm",
          "Rubella - igg",
          "Rubella - igm",
          "Toxo gondii - igg",
          "Toxo gondii - igm",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 2 tests)",
        tests: [
          "Herpes simplex virus (hsv)-igg",
          "Herpes simplex virus (hsv)-igm",
        ],
      },
    ],
    desc: "Tests Includes INFECTIOUS DISEASES, Pregnancy",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5200.0,
    price: 2600.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "A TORCH screen is a panel of tests for detecting a set of viral and parasitic infections in pregnant women. During pregnancy, there are high chances of passing this infections from mother to the fetus through the placenta. Early detection and treatment can prevent various complications in the child like heart defects, mental retardation and movement disorders. TORCH 10 also identifies HSV II infection, which causes congenital diseases.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "torch all eight",
    overlayDetails: ["INFECTIOUS DISEASES (6)", "Pregnancy (2)"],
    avgRating: 4.5,
  },

  {
    id: "130",
    productName: "Torch All Ten (10 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "INFECTIOUS DISEASES (Includes 10 tests)",
        tests: [
          "Cytomegalo virus (cmv) - igg",
          "Cytomegalo virus (cmv) - igm",
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus i (hsv)-igm",
          "Herpes simplex virus ii (hsv)-igg",
          "Herpes simplex virus ii (hsv)-igm",
          "Rubella - igg",
          "Rubella - igm",
          "Toxo gondii - igg",
          "Toxo gondii - igm",
        ],
      },
    ],
    desc: "Tests Includes INFECTIOUS DISEASES",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5280.0,
    price: 2640.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "A TORCH profile is group of blood tests to screen infection in fetus as well as in mother. This panel detects for viral and parasitic infections in pregnant women. During pregnancy, there are high chances of passing these infections from mother to the fetus through the placenta. Our TORCH ALL TEN profile includes blood tests for cytomegalovirus, herpes simplex virus I & II, Rubella and Toxoplasma gondii.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "torch all ten",
    overlayDetails: ["INFECTIOUS DISEASES (10)"],
    avgRating: 4.5,
  },

  {
    id: "131",
    productName: "Torch Panel - Igg (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "INFECTIOUS DISEASES (Includes 5 tests)",
        tests: [
          "Cytomegalo virus (cmv) - igg",
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus ii (hsv)-igg",
          "Rubella - igg",
          "Toxo gondii - igg",
        ],
      },
    ],
    desc: "Tests Includes INFECTIOUS DISEASES",
        imgUrl: populartest01,
    category: "Basic",
    mrp: 2880.0,
    price: 1440.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "torch panel - igg",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "torch panel - igg",
    overlayDetails: ["INFECTIOUS DISEASES (5)"],
    avgRating: 4.5,
  },

  {
    id: "132",
    productName: "Tumour Panel (Male) (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cancer Markers (Includes 6 tests)",
        tests: [
          "Alpha feto protein (afp)",
          "Beta hcg",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
          "Free psa",
          "Prostate specific antigen (psa)",
        ],
      },
      {
        categoryName: "Tumor (Includes 1 tests)",
        tests: ["Percent free psa"],
      },
    ],
    desc: "Tests Includes Cancer Markers, Tumor",
        imgUrl: populartest01,
    category: "Basic",
    mrp: 6000.0,
    price: 3000.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "This panel helps you understand if you have cancer. It also helps doctors diagnose your cancer, its specific type, plan a treatment or check the progress of treatment. You can also take this test if you have just finished your cancer treatment, if the disease runs in your family or if you are at risk of developing one due to factors like obesity, smoking, old age, preexisting health conditions. Get Thyrocare's Male Tumour Panel at an affordable price with free home collection service.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "tumour panel (male)",
    overlayDetails: ["Cancer Markers (6)", "Tumor (1)"],
    avgRating: 4.5,
  },

  {
    id: "133",
    productName: "Typhoid Test (2 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Fever (Includes 2 tests)",
        tests: ["Typhoid-igg", "Typhoid-igm"],
      },
    ],
    desc: "Tests Includes Fever",
        imgUrl: populartest01,
    category: "Basic",
    mrp: 820.0,
    price: 410.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "typhoid test",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "typhoid test",
    overlayDetails: ["Fever (2)"],
    avgRating: 4.5,
  },

  {
    id: "134",
    productName: "Urine Analysis And Culture (25 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "Microbiology (Includes 1 tests)",
        tests: ["Urine culture and susceptibility"],
      },
    ],
    desc: "Tests Includes COMPLETE URINE ANALYSIS, Microbiology", 
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2060.0,
    price: 1030.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "urine analysis and culture",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "urine analysis and culture",
    overlayDetails: ["COMPLETE URINE ANALYSIS (24)", "Microbiology (1)"],
    avgRating: 4.5,
  },

  {
    id: "135",
    productName: "Ustsh-lh-fsh-prl (4 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Infertility (Includes 3 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes Infertility, Thyroid",
        imgUrl: populartest01,
    category: "Basic",
    mrp: 2840.0,
    price: 1420.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "ustsh-lh-fsh-prl",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "ustsh-lh-fsh-prl",
    overlayDetails: ["Infertility (3)", "Thyroid (1)"],
    avgRating: 4.5,
  },

  {
    id: "136",
    productName: "Vitamin B Complex Profile (8 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Vitamin (Includes 8 tests)",
        tests: [
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/p5p",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
        ],
      },
    ],
    desc: "Tests Includes Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3700.0,
    price: 1850.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "A combination of 8 Vitamins namely, B1, B2, B3, B5, B6, B7, B9, and B12 ensures the proper functioning of the body. These vitamins obtained by the body from dietary products, are essential for various body functions like red blood cells production, boosting immunity, overcoming depression and stress, lowering bad cholesterol while increasing good cholesterol, breaking down fats and carbohydrates to produce energy, hormone production, improving skin, hair, and nails. Deficiency of Vitamin B can result in low hemoglobin, depression, weakness, neurological deficits, birth defects in babies, or increased chances of infections. Book a Vitamin B Complex profile at a very low cost. Also, get the home collection for this test.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "vitamin b complex profile",
    overlayDetails: ["Vitamin (8)"],
    avgRating: 4.5,
  },

  {
    id: "137",
    productName: "Vitamin D Profile (3 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Vitamin (Includes 3 tests)",
        tests: ["Vitamin d total", "Vitamin d2", "Vitamin d3"],
      },
    ],
    desc: "Tests Includes Vitamin",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4200.0,
    price: 2100.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Vitamin D helps control the levels of phosphorus and calcium in the body. It also keeps our bones and teeth strong. Vitamin D Profile helps measure the level of Vitamin D using blood sample. Abnormal levels can underline potential health conditions, such as osteoporosis, rickets and osteomalacia. Book the Vitamin D profile now and get a free home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "vitamin d profile",
    overlayDetails: ["Vitamin (3)"],
    avgRating: 4.5,
  },

  {
    id: "138",
    productName: "Widal (4 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Fever (Includes 4 tests)",
        tests: [
          "Salmonella paratyphi – a (h)",
          "Salmonella paratyphi – b (h)",
          "Salmonella typhi – h)",
          "Salmonella typhi - o",
        ],
      },
    ],
    desc: "Tests Includes Fever",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 480.0,
    price: 240.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "widal",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "widal",
    overlayDetails: ["Fever (4)"],
    avgRating: 4.5,
  },

  {
    id: "139",
    productName: "Widal Tube Agglutination (4 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Fever (Includes 4 tests)",
        tests: [
          "Salmonella paratyphi – a (h)",
          "Salmonella paratyphi – b (h)",
          "Salmonella typhi – h",
          "Salmonella typhi - o",
        ],
      },
    ],
    desc: "Tests Includes Fever",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 820.0,
    price: 410.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Widal Tube Agglutination Test is a serological assay that diagnoses typhoid fever by detecting specific antibodies against Salmonella typhi. This test measures the presence of agglutinins (antibodies) in the serum that react with the bacterial antigens of Salmonella typhi, for diagnosing acute typhoid fever. Book test at affordable rates. Lab visits and FREE home sample collection available. Get accurate and timely diagnosis in less than 6 hrs for correct treatment.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "widal tube agglutination",
    overlayDetails: ["Fever (4)"],
    avgRating: 4.5,
  },

  {
    id: "140",
    productName: "Winter Fever Panel Advanced (39 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "NEUTROPHILS-ABSOLUTE COUNT",
          "RED CELL DISTRIBUTION-WIDTH - SD(RDW-SD)",
          "MONOCYTES-ABSOLUTE COUNT",
          "TOTAL RBC",
          "MEAN CORP.HEMO.CONC(MCHC)",
          "BASOPHILS",
          "HEMATOCRIT(PCV)",
          "PLATELET COUNT",
          "EOSINOPHILS",
          "RED CELL DISTRIBUTION WIDTH (RDW-CV)",
          "MEAN CORPUSCULAR HEMOGLOBIN(MCH)",
          "IMMATURE GRANULOCYTE PERCENTAGE(IG%)",
          "LYMPHOCYTE",
          "MEAN CORPUSCULAR VOLUME(MCV)",
          "PLATELET DISTRIBUTION WIDTH(PDW)",
          "NUCLEATED RED BLOOD CELLS",
          "PLATELET TO LARGE CELL RATIO(PLCR)",
          "HEMOGLOBIN",
          "MONOCYTES",
          "BASOPHILS-ABSOLUTE COUNT",
          "IMMATURE GRANULOCYTES(IG)",
          "LYMPHOCYTES-ABSOLUTE COUNT",
          "NUCLEATED RED BLOOD CELLS %",
          "PLATELETCRIT(PCT)",
          "MEAN PLATELET VOLUME(MPV)",
          "TOTAL LEUCOCYTES COUNT (WBC)",
          "NEUTROPHILS",
          "EOSINOPHILS-ABSOLUTE COUNT",
        ],
      },
      {
        categoryName: "Fever (Includes 3 tests)",
        tests: ["Chikungunya-igm", "Plasmodium falciparum", "Plasmodium vivax"],
      },
      {
        categoryName: "Infection (Includes 3 tests)",
        tests: [
          "Dengue-igg - elisa",
          "Dengue-igm - elisa",
          "Dengue ns1 antigen - elisa",
        ],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Other Counts (Includes 1 tests)",
        tests: ["Erythrocyte sedimentation rate (esr)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, COMPLETE HEMOGRAM, Fever, Infection, Liver, Other Counts",
    imgUrl: populartest01,
    category: "Basic",
    price: 0.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "winter fever panel advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "winter fever panel advanced",
    overlayDetails: [
      "Complete Risk Markers (1)",
      "Complete Hemogram (28)",
      "Fever (3)",
      "Infection (3)",
      "Liver (3)",
      "Other Counts (1)",
    ],
    avgRating: 4.5,
  },

  // {
  //   id: "141",
  //   productName: "winter fever panel advanced (rapid) (40 Tests)",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "Cardiac Risk Markers (Includes 1 tests)",
  //       tests: ["C-reactive protein (crp)"],
  //     },
  //     {
  //       categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
  //       tests: [
  //         "NEUTROPHILS-ABSOLUTE COUNT",
  //         "RED CELL DISTRIBUTION-WIDTH - SD(RDW-SD)",
  //         "MONOCYTES-ABSOLUTE COUNT",
  //         "TOTAL RBC",
  //         "MEAN CORP.HEMO.CONC(MCHC)",
  //         "BASOPHILS",
  //         "HEMATOCRIT(PCV)",
  //         "PLATELET COUNT",
  //         "EOSINOPHILS",
  //         "RED CELL DISTRIBUTION WIDTH (RDW-CV)",
  //         "MEAN CORPUSCULAR HEMOGLOBIN(MCH)",
  //         "IMMATURE GRANULOCYTE PERCENTAGE(IG%)",
  //         "LYMPHOCYTE",
  //         "MEAN CORPUSCULAR VOLUME(MCV)",
  //         "PLATELET DISTRIBUTION WIDTH(PDW)",
  //         "NUCLEATED RED BLOOD CELLS",
  //         "PLATELET TO LARGE CELL RATIO(PLCR)",
  //         "HEMOGLOBIN",
  //         "MONOCYTES",
  //         "BASOPHILS-ABSOLUTE COUNT",
  //         "IMMATURE GRANULOCYTES(IG)",
  //         "LYMPHOCYTES-ABSOLUTE COUNT",
  //         "NUCLEATED RED BLOOD CELLS %",
  //         "PLATELETCRIT(PCT)",
  //         "MEAN PLATELET VOLUME(MPV)",
  //         "TOTAL LEUCOCYTES COUNT (WBC)",
  //         "NEUTROPHILS",
  //         "EOSINOPHILS-ABSOLUTE COUNT",
  //       ],
  //     },
  //     {
  //       categoryName: "Fever (Includes 2 tests)",
  //       tests: ["Plasmodium falciparum", "Plasmodium vivax"],
  //     },
  //     {
  //       categoryName: "Infection (Includes 5 tests)",
  //       tests: [
  //         "Chikungunya - igg rapid test",
  //         "Chikungunya - igm rapid test",
  //         "Dengue - igg rapid test",
  //         "Dengue - igm rapid test",
  //         "Dengue ns1 antigen rapid test",
  //       ],
  //     },
  //     {
  //       categoryName: "Liver (Includes 3 tests)",
  //       tests: [
  //         "Sgot / sgpt ratio",
  //         "Aspartate aminotransferase (sgot )",
  //         "Alanine transaminase (sgpt)",
  //       ],
  //     },
  //     {
  //       categoryName: "Other Counts (Includes 1 tests)",
  //       tests: ["Erythrocyte sedimentation rate (esr)"],
  //     },
  //   ],
  //   desc: "Tests Includes Lipid, Complete Hemogram, Toxic Elements, Thyroid, Diabetes, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Elements, Hormone ",
  //   imgUrl: populartest01,
  //   category: "Basic",
  //   price: 2999.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   overlayTitle: "winter fever panel advanced (rapid)",
  //   overlayDetails: [
  //     "Cardiac Risk Markers (1)",
  //     "Complete Hemogram (28)",
  //     "Fever (2)",
  //     "Infection (5)",
  //     "Liver (3)",
  //     "Other Counts (1)",
  //   ],
  //   avgRating: 4.5,
  // },

  // {
  //   id: "142",
  //   productName: "winter fever panel basic",
  //   type: "package",
  //   includedTests: [
  //     {
  //       categoryName: "winter fever panel basic",
  //       tests: [],
  //     },
  //   ],
  //   desc: "Tests Includes Lipid, Complete Hemogram, Toxic Elements, Thyroid, Diabetes, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Elements, Hormone ",
  //   imgUrl: populartest01,
  //   category: "Basic",
  //   price: 1499.0,
  //   discount: 30,
  //   shortDesc: "Sample Type: Blood",
  //   description:
  //     "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
  //   reviews: [
  //     {
  //       rating: 4.7,
  //       text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  //     },
  //   ],
  //   overlayTitle: "winter fever panel basic",
  //   overlayDetails: ["winter fever panel basic"],
  //   avgRating: 4.5,
  // },

  {
    id: "143",
    productName: "Women Cancer Screening Panel - Advanced (12 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cancer Marker (Includes 3 tests)",
        tests: ["Ca125", "Roma - premenopausal", "Roma - postmenopausal"],
      },
      {
        categoryName: "Cancer Markers (Includes 4 tests)",
        tests: [
          "Alpha feto protein (afp)",
          "Ca 15.3",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
        ],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["Lactate dehydrogenase (ldh)"],
      },
      {
        categoryName: "Hematology (Includes 1 tests)",
        tests: ["Human epididymis protein 4"],
      },
      {
        categoryName: "Pregnancy (Includes 1 tests)",
        tests: ["Beta hcg"],
      },
      {
        categoryName: "Thyroid (Includes 1 tests)",
        tests: ["Thyroglobulin (tg)"],
      },
      {
        categoryName: "Tumor Marker (Includes 1 tests)",
        tests: ["Chromogranin a"],
      },
    ],
    desc: "Tests Includes Cancer Marker, Cancer Markers, Cardiac Risk Markers, Hematology, Pregnancy, Thyroid, Tumor Marker",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 12360.0,
    price: 6180.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
    "women cancer screening panel - advanced",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "women cancer screening panel - advanced",
    overlayDetails: [
      "Cancer Marker (3)",
      "Cancer Markers (4)",
      "Cardiac Risk Markers (1)",
      "Hematology (1)",
      "Pregnancy (1)",
      "Thyroid (1)",
      "Tumor Marker (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "144",
    productName: "Women Cancer Screening Panel - Basic (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cancer Markers (Includes 5 tests)",
        tests: [
          "Alpha feto protein (afp)",
          "Ca-125",
          "Ca 15.3",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 1 tests)",
        tests: ["Beta hcg"],
      },
    ],
    desc: "Tests Includes Cancer Markers, Pregnancy",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4120.0,
    price: 2060.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "women cancer screening panel - basic",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "women cancer screening panel - basic",
    overlayDetails: ["Cancer Markers (5)", "Pregnancy (1)"],
    avgRating: 4.5,
  },

  {
    id: "145",
    productName: "Womens Hairfall Screening - Advanced (51 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 2 tests)",
        tests: ["Anti nuclear antibodies (ana)", "Phosphorous"],
      },
      {
        categoryName: "Cardiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "NEUTROPHILS-ABSOLUTE COUNT",
          "RED CELL DISTRIBUTION-WIDTH - SD(RDW-SD)",
          "MONOCYTES-ABSOLUTE COUNT",
          "TOTAL RBC",
          "MEAN CORP.HEMO.CONC(MCHC)",
          "BASOPHILS",
          "HEMATOCRIT(PCV)",
          "PLATELET COUNT",
          "EOSINOPHILS",
          "RED CELL DISTRIBUTION WIDTH (RDW-CV)",
          "MEAN CORPUSCULAR HEMOGLOBIN(MCH)",
          "IMMATURE GRANULOCYTE PERCENTAGE(IG%)",
          "LYMPHOCYTE",
          "MEAN CORPUSCULAR VOLUME(MCV)",
          "PLATELET DISTRIBUTION WIDTH(PDW)",
          "NUCLEATED RED BLOOD CELLS",
          "PLATELET TO LARGE CELL RATIO(PLCR)",
          "HEMOGLOBIN",
          "MONOCYTES",
          "BASOPHILS-ABSOLUTE COUNT",
          "IMMATURE GRANULOCYTES(IG)",
          "LYMPHOCYTES-ABSOLUTE COUNT",
          "NUCLEATED RED BLOOD CELLS %",
          "PLATELETCRIT(PCT)",
          "MEAN PLATELET VOLUME(MPV)",
          "TOTAL LEUCOCYTES COUNT (WBC)",
          "NEUTROPHILS",
          "EOSINOPHILS-ABSOLUTE COUNT",
        ],
      },
      {
        categoryName: "Elements (Includes 1 tests)",
        tests: ["Serum zinc"],
      },
      {
        categoryName: "Hormone (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "Infectious Diseases (Includes 1 tests)",
        tests: ["Vdrl (rpr) for syphilis"],
      },
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
          "Sex hormone binding globulin (shbg)",
        ],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 2 tests)",
        tests: ["Cortisol", "Magnesium"],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Calcium"],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Thyroid stimulating hormone (tsh)",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Elements, Hormone, Infectious Diseases, Infertility, Iron Deficiency, Metabolic, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 5498.0,
    price: 2749.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Worried about hair fall? Trying different products but not getting satisfactory results? It's time to stop hear-say methods and get hair fall screening done! Try Thyrocare’s new WOMEN'S HAIR FALL SCREENING - ADVANCE profile, a comprehensive package of 47 tests that covers thyroid testing, complete blood count, iron deficiency profile, inflammatory markers, hormones, vitamins, minerals and essential elements only at Rs 2749/- Book now and get a free home collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "womens hairfall screening - advanced",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)",
      "Elements (1)",
      "Hormone (1)",
      "Infectious Diseases (1)",
      "Infertility (4)",
      "Iron Deficiency (5)",
      "Metabolic (2)",
      "Renal (1)",
      "Thyroid (3)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "146",
    productName: "Troponin I Heart Attack Risk (51 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "troponin i heart attack risk",
        tests: ["troponin i heart attack risk"],
      },
    ],
    desc: "Tests Includes troponin i heart attack risk",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1302.0,
    price: 650.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "troponin i heart attack risk",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "troponin i heart attack risk",
    overlayDetails: ["troponin i heart attack risk"],
    avgRating: 4.5,
  },

  {
    id: "147",
    productName: "High Sensitivity C-Reactive Protein (hs-crp)",
    type: "package",
    includedTests: [
      {
        categoryName: "high sensitivity c-reactive protein (hs-crp)",
        tests: ["high sensitivity c-reactive protein (hs-crp)"],
      },
    ],
    desc: "Tests Includes high sensitivity c-reactive protein (hs-crp)",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 900.0,
    price: 450.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "high sensitivity c-reactive protein (hs-crp)",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "high sensitivity c-reactive protein (hs-crp)",
    overlayDetails: ["high sensitivity c-reactive protein (hs-crp)"],
    avgRating: 4.5,
  },

  {
    id: "148",
    productName: "Jaanch - Diabetic Screen (48 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Diabetes (3)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
    ],
    desc: "Tests Includes Complete Hemogram, Diabetes, Lipid, Renal",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 2398.0,
    price: 1199.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "jaanch - diabetic screen",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch - diabetic screen",
    overlayDetails: [
      "COMPLETE HEMOGRAM (28)",
      "Diabetes (3)",
      "Lipid (10)",
      "Renal (7)",
    ],
    avgRating: 4.5,
  },

  {
    id: "149",
    productName: "Hba1c",
    type: "package",
    includedTests: [
      {
        categoryName: "hba1c",
        tests: ["hba1c"],
      },
    ],
    desc: "Tests Includes hba1c",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 700.0,
    price: 350.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "HbA1c, also known as Hemoglobin A1c or A1c, is glycosylated hemoglobin or glycohemoglobin in which the glucose molecule present in the blood non-enzymatically binds to hemoglobin. HbA1c test reflects those RBC that are present in the blood at the time of test and measures average blood glucose concentration over 3-4 months. This gives an indication of long-term blood glucose control which serves as a regular monitoring tool if a person has been diagnosed with diabetes. According to the American Diabetes Association, this test is mandatory for diabetic patients and should be performed four times a year. HbA1c test is available at a discounted rate, along with the home collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "hba1c",
    overlayDetails: ["hba1c"],
    avgRating: 4.5,
  },

  {
    id: "150",
    productName: "Jaanch Monsoon Fever Panel - Advanced (40 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 1 Tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Fever (Includes 5 Tests)",
        tests: [
          "Chikungunya-igm",
          "Plasmodium falciparum",
          "Plasmodium vivax",
          "Typhoid-igg",
          "Typhoid-igm",
        ],
      },
      {
        categoryName: "Infection (Includes 1 Tests)",
        tests: ["Dengue-igm - elisa"],
      },
      {
        categoryName: "Infectious Diseases (Includes 1 Tests)",
        tests: ["Dengue ns1 antigen - elisa"],
      },
      {
        categoryName: "Liver (Includes 3 Tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Other Counts (Includes 1 Tests)",
        tests: ["Erythrocyte sedimentation rate (esr)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, Complete Hemogram, Fever, Infection, Infectious Disease, Liver, Other Counts",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 4598.0,
    price: 2299.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Dengue, Malaria, Typhoid and Chikungunya are common during Monsoons. To keep one safe from them, early and accurate diagnosis is a must. Jaanch Monsoon Fever Panel - Advanced provides tests specifically curated for malaria, typhoid, dengue, chikungunya and more. Get accurate and timely diagnosis in less than 6 hrs for correct treatment.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch monsoon fever panel - advanced",
    overlayDetails: [
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)",
      "Fever (5)",
      "Infection (1)",
      "Infectious Diseases (1)",
      "Liver (3)",
      "Other Counts (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "151",
    productName: "Jaanch Monsoon Fever Mini (34 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Fever (Includes 6 Tests)",
        tests: [
          "Plasmodium falciparum",
          "Plasmodium vivax",
          "Salmonella paratyphi – a (h)",
          "Salmonella paratyphi – b (h)",
          "Salmonella typhi – h",
          "Salmonella typhi - o",
        ],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, Fever",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1280.0,
    price: 640.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The monsoon season brings the risk of health problems like cold and cough along with the danger of diseases such as malaria and typhoid. Make the most of Jaanch Monsoon Fever Mini, a package designed to safeguard overall health. The package includes malaria, typhoid and complete blood count (CBC) tests to help patients stay proactive and protected. Get accurate and timely diagnosis in less than 6 hrs for correct treatment.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch monsoon fever mini",
    overlayDetails: ["COMPLETE HEMOGRAM (28)", "Fever (6)"],
    avgRating: 4.5,
  },

  {
    id: "152",
    productName: "Jaanch Monsoon Fever Panel - Basic (38 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Cardiac Risk Markers (Includes 1 Tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Fever (Includes 4 tests)",
        tests: [
          "Plasmodium falciparum",
          "Plasmodium vivax",
          "Typhoid-igg",
          "Typhoid-igm",
        ],
      },
      {
        categoryName: "Infectious Diseases (Includes 1 tests)",
        tests: ["Dengue ns1 antigen - elisa"],
      },
      {
        categoryName: "Liver (Includes 3 tests)",
        tests: [
          "Sgot / sgpt ratio",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "Other Counts (Includes 1 tests)",
        tests: ["Erythrocyte sedimentation rate (esr)"],
      },
    ],
    desc: "Tests Includes Cardiac Risk Markers, COMPLETE HEMOGRAM, Fever, Infectious Diseases, Liver, Other Counts",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3798.0,
    price: 1899.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The monsoon season brings in many infections. Prepare to fight health problems with our newly launched, doctor-curated Jaanch Monsoon Fever Panel - Basic. This specially designed profile includes parameters for fever and infections like dengue, malaria, typhoid, and more tests at extremely competitive prices. Get accurate and timely diagnosis in less than 6 hrs for correct treatment.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch monsoon fever panel - basic",
    overlayDetails: [
      "Cardiac Risk Markers(1)",
      "COMPLETE HEMOGRAM (28)",
      "Fever (4)",
      "Infectious Diseases (1)",
      "Liver (3)",
      "Other Counts (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "153",
    productName: "Jaanch - Mens Hairfall Screening Advanced (47 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 2 tests)",
        tests: ["Anti nuclear antibodies (ana)", "Phosphorous"],
      },
      {
        categoryName: "Cadiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Elements (Includes 1 tests)",
        tests: ["Serum zinc"],
      },
      {
        categoryName: "Infertility (Includes 1 tests)",
        tests: ["Sex hormone binding globulin (shbg)"],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 2 tests)",
        tests: ["Cortisol", "Magnesium"],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Calcium"],
      },
      {
        categoryName: "Steroid Hormone (Includes 1 tests)",
        tests: ["Dihydrotestosterone (dht)"],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitamin (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
      {
        categoryName: "Vitamins (Includes 1 tests)",
        tests: ["Vitamin b-12"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Elements, Infertility, Iron Deficiency, Metabolic, Renal, Steroid Hormone, Thyroid, Vitamin, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Too much hair fall and don’t know why? It could be genetic or due to lifestyle factors. Get to the root cause today with Thyrocare’s newly launched Jaanch - Mens Hairfall Screening Advanced profile. It includes 43 crucial parameters for thyroid health, complete blood count, iron deficiency profile, inflammatory markers, vitamins, minerals, and essential elements at extremely affordable prices. Book your test now and get a FREE home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch - mens hairfall screening advanced",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)",
      "Elements (1)",
      "Infertility (1)",
      "Iron Deficiency (5)",
      "Metabolic (2)",
      "Renal (1)",
      "Steroid Hormone (1)",
      "Thyroid (3)",
      "Vitamin (1)",
      "Vitamins (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "154",
    productName: "Jaanch - Womens Hairfall Screening Advanced (51 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Arthritis (Includes 2 tests)",
        tests: ["Anti nuclear antibodies (ana)", "Phosphorous"],
      },
      {
        categoryName: "Cadiac Risk Markers (Includes 1 tests)",
        tests: ["C-reactive protein (crp)"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },

      {
        categoryName: "Elements (Includes 1 tests)",
        tests: ["Serum zinc"],
      },
      {
        categoryName: "Hormone (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "Infectious Diseases (Includes 1 tests)",
        tests: ["Vdrl (rpr) for syphilis"],
      },
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
          "Sex hormone binding globulin (shbg)",
        ],
      },
      {
        categoryName: "Iron Deficiency (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "Metabolic (Includes 2 tests)",
        tests: ["Cortisol", "Magnesium"],
      },
      {
        categoryName: "Renal (Includes 1 tests)",
        tests: ["Calcium"],
      },

      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },

      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Arthritis, Cardiac Risk Markers, Complete Hemogram, Elements, Hormone, Infectious Disease, Infertility, Iron Deficiency, Metabolic, Renal, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Worried about hair fall? Trying different products but not getting satisfactory results? It's time to stop following hear-say methods and get hair fall screening done. Try Thyrocare’s newly launched doctor-curated Jaanch - Womens Hairfall Screening Advanced profile, a comprehensive package of 47 tests that evaluate important health parameters like thyroid health, complete blood count, iron deficiency profile, inflammatory markers, hormones, vitamins, minerals and essential elements at extremely affordable rates. Book now and get a FREE home sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch - womens hairfall screening advanced",
    overlayDetails: [
      "Arthritis (2)",
      "Cardiac Risk Markers (1)",
      "Complete Hemogram (28)","Elements (1)", "Hormone (1)",
      "Inectious Disease (1)",
      "Infertility (4)",
      "Iron Deficiency (5)",
      "Metabolic (2)",
      "Renal (1)",
      "Thyroid (3)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "155",
    productName: "Jaanch - Mens Skin Care Checkup (36 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Allergy (Includes 1 tests)",
        tests: ["Total ige"],
      },
      {
        categoryName: "Arthritis (Includes 1 tests)",
        tests: ["Anti nuclear antibodies (ana)"],
      },
      {
        categoryName: "Autoimmunity (Includes 1 tests)",
        tests: ["Anti - dsdna"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Allergy, Arthritis, Autoimmunity, Complete Hemogram, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 3998.0,
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Skin problems can mean potential health problems. Thyrocare’s newly launched Jaanch - Mens Skin Care Checkup is a doctor-curated profile with 32 parameters that diagnose skin diseases along with any underlying conditions, like thyroid disorders, nutrient deficiencies, allergies, infections and hormonal imbalance. Book this profile today at affordable rates and also avail a FREE sample collection.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch - mens skin care checkup",
    overlayDetails: [
      "Allergy (1)",
      "Arthritis (1)",
      "Autoimmunity (1)",
      "Complete Hemogram (28)",
      "Thyroid (3)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "156",
    productName: "Jaanch - Womens Skin Care Checkup (43 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Allergy (Includes 1 tests)",
        tests: ["Total ige"],
      },
      {
        categoryName: "Arthritis (Includes 1 tests)",
        tests: ["Anti nuclear antibodies (ana)"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 Tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "Diabetes (Includes 3 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
        ],
      },
      {
        categoryName: "Hormone (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "Infertility (Includes 4 tests)",
        tests: [
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "Pregnancy (Includes 2 tests)",
        tests: ["Estradiol/oestrogen (e2)", "Progesterone"],
      },
      {
        categoryName: "Thyroid (Includes 3 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
      {
        categoryName: "Vitamins (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes Allergy, Arthritis, Complete Hemogram, Diabetes, Hormone, Infertility, Pregnancy, Thyroid, Vitamins",
    imgUrl: populartest01,
    category: "Basic",
    mrp: 1300.0,
    price: 650.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "jaanch - womens skin care checkup",
    overlayDetails: [
      "Allergy (1)",
      "Arthritis (1)",
      "Complete Hemogram (28)",
      "Diabetes (3)",
      "Hormone (1)",
      "Infertility (4)",
      "Pregnancy (2)",
      "Thyroid (1)",
      "Vitamins (2)",
    ],
    avgRating: 4.5,
  },
];
